<template>
  <div v-resize="onResize">
    <!-- Card Mode Desktop Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == false"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 110px;">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="title">Daftar Karyawan Cuti</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Hari Ini -- '+listcuti.length+' Karyawan'"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar
            size="45px"
            class="rounded-lg mt-4 ml-4"
            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          >
            <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">mdi-format-list-text</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </div>

      <v-card-actions> 
        <v-btn
          depressed
          @click="DialogDaftarCuti = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>Detail</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
          <v-badge dot color="blue darken-4" offset-x="-2" offset-y="-10" v-show="listcuti.length == 0 ? false : true"></v-badge>
        </v-btn> 
      </v-card-actions>
    </v-card>
    <!-- Card Mode Desktop End -->

    <!-- Card Mode Mobile Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == true"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <div style="height: 185px;">  
        <v-card-actions>    
          <v-avatar
            size="55px"
            class="rounded-lg ma-2 mb-n2"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-2'"
          >
            <v-icon large :color="$vuetify.theme.dark ? 'error' : 'grey darken-4'">mdi-format-list-text</v-icon>
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="DialogDaftarCuti = true"
            class="text-capitalize rounded-lg mr-2">
            <span>Detail</span>
            <v-badge dot color="blue darken-4" offset-x="-2" offset-y="-10" v-show="listcuti.length == 0 ? false : true"></v-badge>
          </v-btn> 
        </v-card-actions>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="title">Daftar Karyawan Cuti</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1" v-text="'Hari Ini -- '+listcuti.length+' Karyawan'"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <!-- Card Mode Mobile end -->

    <!-- Dialog Daftar Karyawan Yang Cuti Start -->
    <v-dialog
      v-model="DialogDaftarCuti"
      scrollable
      max-width="450"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
    >
      <v-card outlined class="rounded-lg" :color="$vuetify.theme.dark ? '' : 'grey lighten-4'">
        <v-card-actions style="height: 64px;" :class="$vuetify.theme.dark ? 'grey darken-4' : 'white'">
          <v-list-item two-line>
            <v-list-item-action class="ml-n7" v-show="mobile == true">
              <v-btn class="mr-n3" icon text height="47px" width="47px" @click="DialogDaftarCuti = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content class="mx-n4">
              <v-list-item-title :class="mobile ? 'title' : 'text-h5'">Daftar Karyawan Cuti</v-list-item-title>
              <v-list-item-subtitle>
                <span v-text="listcuti.length == 0 ? '' : 'Jumlah Karyawan Cuti Hari Ini : '"></span>
                <strong v-text="listcuti.length" v-show="listcuti.length == 0 ? false : true"></strong>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-show="mobile == false" class="mr-n7">
              <v-btn icon height="47px" width="47px" @click="DialogDaftarCuti = false">
                <v-icon >mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <!-- Alert Perigatan Kontrak -->
            <div>
              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="listcuti.length == 0"
                type="info"
              >
                Hari Ini Tidak ada yang cuti
              </v-alert>

              <!-- <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'"
                type="warning"
              >
                Cuti Di Ajukan
              </v-alert> -->

              <!-- <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'"
                type="success"
              >
                Cuti Di Setujui
              </v-alert>
              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'"
                type="warning"
                v-text="'Cuti Tidak Disetujui Oleh '+ datacuti.length > 0 ? datacuti.approvalcuti[datacuti.approvalcuti.length-1].ApproveBy : ''"
              >

              </v-alert> -->
            </div> 

            <v-list outlined class="rounded-lg" v-show="listcuti.length == 0 ? false : true">
              <template v-for="(item, index) in listcuti">
                <v-list-item :key="index">
                  <!-- <v-list-item-avatar>
                    <v-img :src="item.KodeKaryawan"></v-img>
                  </v-list-item-avatar> -->

                  <v-list-item-content>
                    <v-list-item-title v-html="item.karyawan.Nama"></v-list-item-title>
                    <v-list-item-subtitle>
                      <!-- <v-icon left small>mdi-clock</v-icon> -->
                      <!-- <span v-html="item.JamMulai"></span> &mdash; <span v-html="item.JamSelesai"></span> -->
                      <!-- <v-list-item-subtitle>
                      <v-icon left small>mdi-calendar</v-icon>
                      <span v-html="item.TanggalMulai"></span> &mdash; <span v-html="item.TanggalSelesai"></span>
                    </v-list-item-subtitle> -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider
                  v-if="(item, index) < listcuti.length - 1"
                  :key="index+'divider'"
                ></v-divider>
              </template>
            </v-list>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog Daftar Karyawan Yang Cuti End -->
  </div>
</template>

<script>
import api from "@/services/http";
export default {
  props: ['datakaryawan','jmlanak','kontrak','datacuti','foto','listcuti'],
  data () {
    return {
      DibuatTgl:'',
      DialogDaftarCuti:false,
      dialog_verifikasi_kontrak: false,
      mobile:null,
      detailcuti2:this.datacuti.approvalcuti[2],
      ShowValidasiKontrak:false,
      MasaKontrak:this.kontrak ? this.CekMasaKontrak() : 0,
      today:new Date().toJSON().slice(0,10).split('-').join('-'),
      windowSize: {x: 0, y: 0},
      token:null,
    }
  },

  mounted(){
    // console.log(this.kontrak)
    this.token = localStorage.getItem('token')
    // this.getdata()
  },

  computed: {

  },

  watch: {
    // datacuti(){
    //   console.log(this.datacuti)
    // },
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {
    CetakSurat(){
      console.log('dor')
      console.log('ber',this.detailcuti)
    },
    BukaSurat(){
      console.log('data',this.datacuti)
      let DibuatTgl = this.datacuti.DibuatTgl
      this.DibuatTgl = this.FormatTanggal(DibuatTgl)
    },
    FormatTanggal(x){
      var todayTime = new Date(x);
      var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      var month = bulan[todayTime .getMonth()];
      var day = todayTime .getDate();
      var year = todayTime .getFullYear();
      return day + " " + month + " " + year;  
    },
    LanjutKontrak(){
      // alert('tes')
      this.dialog_verifikasi_kontrak = false
      api.post('/notifikasi?token='+this.token,{
            Kategori: "Info",
            Judul: "Perpanjang Kontrak",
            Konten: "Menyetujui Perpanjang Kontrak Kerja",
            Attachment:null,
            Dari: this.$user.KodeKaryawan,
            Tujuan: "Human Resources",
            },
            { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } }
            )
            .then((res)=>{
              if (res) {
                // this.Alert = true
                // console.log('ok lanjut')
              } else {
                //
              }
            })
    },
    CekMasaKontrak(){
      var TanggalSekarang = new Date()
      var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
      var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
      var Tahun = TanggalSekarang.getFullYear();
      const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
      const TanggalAwal = new Date(this.kontrak.TanggalAkhir)
      const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
      let HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
      // this.MasaKontrak = HitungHari
      HitungHari = TanggalAkhir > TanggalAwal ? 0 : HitungHari
      if (HitungHari < 8) {this.ShowValidasiKontrak = true}
      return HitungHari;
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>
<style>
  table{
    border-collapse:collapse;
    width: 100%;
  }
  .qrcode {
  display: inline-block;
  /* font-size: 0;
  margin-bottom: 0; */
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 25%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  width: 20%;
}
</style>