<template>
  <div>
    <div>
      <v-sheet class="rounded-b-lg" v-show="mobile == true" style="height: 175px;" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-4'">
      </v-sheet>
    </div>

    <div class="pa-4" v-resize="onResize">
      <!-- <v-btn @click="DialogMenyapaUser = true"></v-btn> -->
      <!-- <v-dialog
        v-model="DialogMenyapaUser"
        max-width="450"
        hide-overlay
      >
        <v-card max-width="450" rounded="lg" class="pa-3" dark color="red darken-4">
          <div class="mb-n3 d-flex flex-row-reverse"><v-icon @click="DialogMenyapaUser = false">mdi-close</v-icon></div>
          <v-card-title class="text-h5 mb-n7" v-text="'Hi, '+datakaryawan.Nama"></v-card-title>
          <v-card-title class="text-h5">Welcome!</v-card-title>
          <v-card-subtitle>Trinity Support System</v-card-subtitle>
        </v-card>
      </v-dialog> -->

      <!-- <v-img v-show="mobile == true" @click="home" class="ml-2 mr-2" max-width="30" src="../assets/LogoTrinity.png" style="cursor: pointer;"></v-img>
      <v-toolbar-title @click="home" v-show="mobile == true">
        <router-link to="home" tag="span" exact style="cursor: pointer;">
          <div class="title mb-n1">Selamat Datang di,</div>
          <div class="caption">Trinity Support System</div>
        </router-link>
      </v-toolbar-title> -->
      

      <div :style="mobile ? 'margin-top: -175px;' : ''">
        <v-toolbar v-show="mobile == true" flat color="transparent" class="mb-3">
          <v-img v-show="loading == false" class="ml-n4 mr-2" max-width="30" src="../assets/LogoTrinity.png"></v-img>
          <v-toolbar-title v-show="loading == false">
            <div class="title mb-n1 white--text">Selamat Datang di TSS</div>
            <div class="caption white--text">Trinity Support System</div>
          </v-toolbar-title>
          <v-toolbar-title v-show="loading == true" class="ml-n4">
            <div><v-skeleton-loader flat type="image" class="rounded-pill mb-1" height="15px" width="200px"></v-skeleton-loader></div>
            <div><v-skeleton-loader flat type="image" class="rounded-pill" height="15px" width="150px"></v-skeleton-loader></div>
          </v-toolbar-title>
        </v-toolbar>

        
        <div>
          <v-sheet
            v-show="loading == true && mobile == false"
            class="rounded-lg col-12 mb-4"
            :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
          >
            <v-skeleton-loader
              width="300"
              type="heading"
            ></v-skeleton-loader>
          </v-sheet>

          <v-toolbar
            id="toolbar"
            flat
            fixed
            outlined
            v-show="loading == false && mobile == false"
            class="mb-4 rounded-lg"
            :color="$vuetify.theme.dark ? 'transparent' : 'transparent'"
          >
            <v-toolbar-title>
              <span class="text-h5">Dashboard</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- <div class="text-center" v-show="mobile == false">
              <v-sheet class="ma-2 rounded-pill" outlined>
                <v-btn
                  text
                  rounded
                  height="48"
                  class="text-capitalize"
                >
                  <v-icon class="mr-2">mdi-qrcode-scan</v-icon>
                  <span>Scan QR Untuk Absen</span>
                </v-btn>
              </v-sheet>
            </div> -->

            <div class="text-center">
              <v-menu
                offset-y
                rounded="lg"
                v-model="MenuSettingHome"
                transition="fade-transition"
                :close-on-content-click="false"
                v-show="!this.datakaryawan.visible"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="MenuSettingHome ? 'error' : ''">mdi-tune</v-icon>
                  </v-btn>
                </template>

                <v-card outlined rounded="lg" width="320">
                  <v-app-bar
                    flat
                    height="64"
                    class="rounded-t-lg"
                    scroll-target="#ScrollMenuSettingHome"
                    :color="$vuetify.theme.dark ? 'dark' : 'white'"
                  >
                    <v-toolbar-title class="title">Setelan Dashboard</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="MenuSettingHome = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-app-bar>

                  <v-divider></v-divider>

                  <v-list
                    id="ScrollMenuSettingHome"
                    class="overflow-y-auto"
                    two-line
                    flat
                    max-height="430"
                  >
                    <v-list-item-group>
                      <v-list-item v-model="enabled">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1 text--primary">Ubah Posisi Dashboard</v-list-item-title>
                            <v-list-item-subtitle v-if="enabled">Aktif</v-list-item-subtitle>
                            <v-list-item-subtitle v-else class="error--text">Tidak Aktif</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-switch 
                              inset
                              v-model="enabled"
                              :input-value="active"
                              color="error"
                            ></v-switch>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <!-- <v-checkbox
              v-show="!this.datakaryawan.visible"
              v-model="enabled"
              label="Mode Edit"
              class="mb-n6 mx-2"
            ></v-checkbox> -->
          </v-toolbar>
        </div>

        <!-- Dialog Absen -->
        <div v-show="mobile == true" class="mb-4">
          <v-card
            outlined
            class="rounded-lg elevation-15"
            :color="$vuetify.theme.dark ? '' : ''"
          >
            <!-- Loading Absen start -->
            <div>
              <v-list-item class="mb-n2" v-show="absen == 0 == true && loading == false">
                <v-list-item-content>
                  <v-list-item-title class="title">Absensi</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="loading == true">
                <v-list-item-content>
                  <v-skeleton-loader class="mb-n2" type="heading"></v-skeleton-loader>
                </v-list-item-content>
              </v-list-item>
              <v-skeleton-loader v-show="loading == true" type="divider"></v-skeleton-loader>
              <v-skeleton-loader v-show="loading == true" type="image" height="50px" class="ma-2 rounded-lg"></v-skeleton-loader>
            </div>
            <!-- Loading Absen end -->

            <v-sheet
              outlined
              class="rounded-lg ma-2"
              :color="$vuetify.theme.dark ? '' : ''" v-show="absen == 0 == false"
            >
              <!-- Scan Masuk start -->
              <v-list-item three-line v-if="absen[0] != undefined">
                <v-list-item-content>
                  <div class="text-overline mb-n1">
                    <span v-text="absen[0].Scan ? 'Scan Masuk' : 'Scan Pulang'"></span>
                  </div>
                  <v-list-item-title class="text-h5 mb-1" v-text="absen[0].ScanMasuk"></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon left small>mdi-map-marker</v-icon>
                    <span v-text="absen[0].Lokasi"></span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon left small>mdi-calendar</v-icon>
                    <span v-text="absen[0].Tanggal"></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>Sukses</v-list-item-action-text>
                  <v-icon large color="success">mdi-check-bold</v-icon>
                </v-list-item-action>
              </v-list-item>
              <!-- Scan Masuk end -->

              <v-divider v-if="absen[1] != undefined"></v-divider>

              <!-- Scan Pulang start -->
              <v-list-item three-line v-if="absen[1] != undefined">
                <v-list-item-content>
                  <div class="text-overline mb-n1">
                    <span v-text="absen[1].Scan ? 'Scan Pulang' : 'Scan Masuk'"></span>
                  </div>
                  <v-list-item-title class="text-h5 mb-1" v-text="absen[1].ScanPulang"></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon left small>mdi-map-marker</v-icon>
                    <span v-text="absen[1].Lokasi"></span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon left small>mdi-calendar</v-icon>
                    <span v-text="absen[1].Tanggal"></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>Sukses</v-list-item-action-text>
                  <v-icon large :color="absen[1].ScanPulang == null ? 'error' : 'success'" v-text="absen[1].ScanPulang == null ? 'mdi-close-circle' : 'mdi-check-bold'"></v-icon>
                </v-list-item-action>
              </v-list-item>
              <!-- Scan Pulang end -->
            </v-sheet>

            <!-- Btn Scan Qr start -->
            <div class="ma-2">
              <v-btn
                block
                dark
                :disabled="loading == true"
                height="50px"
                depressed
                v-show="loading == false && datakaryawan.KategoriAbsen != null"
                :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey darken-2'"
                class="rounded-lg text-capitalize"
                @click="DialogAbsen = true"
              >
                <v-icon left>mdi-qrcode-scan</v-icon>
                <span>Scan QR</span>
              </v-btn>
              <v-btn
                block
                disabled
                height="50px"
                depressed
                v-show="loading == false && datakaryawan.KategoriAbsen == null"
                :color="$vuetify.theme.dark ? 'grey darken-3' : 'error'"
                class="rounded-lg text-capitalize"
              >
                <v-icon left>mdi-qrcode-scan</v-icon>
                <span>Scan QR</span>
              </v-btn>
            </div>

            <div class="text-center mb-2" v-show="absen == 0 == true && loading == false && datakaryawan.KategoriAbsen == null == false">
              <v-icon small left>mdi-information</v-icon>
              <span class="caption mx-auto">Scan QR Untuk Absen</span>
            </div>

            <div class="text-center mb-2" v-show="loading == false && datakaryawan.KategoriAbsen == null == true">
              <v-icon small left color="error">mdi-alert-circle</v-icon>
              <span class="caption mx-auto error--text">Lapor Ke HR / IT Untuk Absen</span>
            </div>
            <!-- Btn Scan Qr end -->
          </v-card>

          <v-dialog
            max-width="300px"
            v-model="DialogAbsen"
          >
            <v-card outlined rounded="lg">
              <v-toolbar flat>
                <v-icon left>mdi-qrcode-scan</v-icon>
                <v-toolbar-title>Scan QR Absen</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <v-btn icon @click="KeluarDialogAbsen">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-toolbar>

              <div class="pa-3">
                <!-- Dialog Scan Masuk -->
                <v-dialog
                  v-model="DialogScanMasuk"
                  max-width="320px"
                  :fullscreen="mobile ? true : false"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" @click="ScanMasuk()" depressed outlien height="55px" block class="text-capitalize mb-3 rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-2'">
                      <span>Scan QR Masuk</span>
                    </v-btn>
                  </template>
                  <v-card
                    outlined
                  >
                    <v-toolbar flat height="64px">
                      <v-btn icon @click="KeluarDialogScanMasuk">
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-toolbar-title v-show="ShowCameraQRMasuk">Scan QR Masuk</v-toolbar-title>
                      <v-toolbar-title v-show="ShowQRCode">QR Masuk</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon v-show="BtnQRCodeMasuk" @click="QRCodeMasuk" class="mr-n3">
                        <v-icon>mdi-qrcode</v-icon>
                      </v-btn>
                      <v-btn icon v-show="BtnCameraQRMasuk" @click="CameraQRMasuk">
                        <v-icon>mdi-scan-helper</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-divider></v-divider>
                    
                    <div v-show="ShowCameraQRMasuk" class="ma-2">
                      <v-sheet outlined style="height: 400px; width: 100%;">
                        <CameraQR :StatusCam="StatusCam" :Kategori="Kategori" @tutup="tutup()"/>
                      </v-sheet>
                    </div>

                    <!-- <div v-show="ShowQRCode" class="ma-2 mr-2 text-center">
                      <v-sheet outlined style="height: 400px; width: 100%;">
                        <QRCode :StatusCamPulang="StatusCamPulang"/>
                      </v-sheet>
                    </div> -->

                    <v-card-actions>
                      <v-sheet outlined class="pa-1 mx-auto rounded-pill">
                        <v-btn-toggle group v-model="ToggleBtnQRCamera" mandatory background-color="primary">
                          <v-btn @click="CameraQRMasuk" class="text-capitalize rounded-xl">
                            <v-icon class="mr-2">mdi-scan-helper</v-icon>
                            Snap QR
                          </v-btn>
                          <v-btn @click="QRCodeMasuk" class="text-capitalize rounded-xl">
                            <v-icon class="mr-2">mdi-qrcode</v-icon>
                            QR Code
                          </v-btn>
                        </v-btn-toggle>
                      </v-sheet>
                    </v-card-actions>

                    <v-card-actions>
                      <v-btn color="error" outlined rounded text class="mx-auto text-capitalize" @click="KeluarDialogScanMasuk">
                        <v-icon left>mdi-close</v-icon>
                        <span class="mr-2">Batal</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog> 

                <!-- Dialog Scan Pulang -->
                <v-dialog
                  v-model="DialogScanPulang"
                  max-width="320px"
                  :fullscreen="mobile ? true : false"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" @click="ScanPulang()" depressed height="55px" block class="text-capitalize rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-2'">
                      <span>Scan QR Pulang</span>
                    </v-btn>
                  </template>
                  <v-card
                    outlined
                    :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                  >
                    <v-toolbar flat>
                      <v-btn icon @click="KeluarDialogScanPulang">
                        <v-icon>mdi-arrow-left</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-toolbar-title v-show="ShowCameraQRPulang">Scan QR Pulang</v-toolbar-title>
                      <v-toolbar-title v-show="ShowQRCodePulang">QR Pulang</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon v-show="BtnQRCodePulang" @click="QRCodePulang" class="mr-n3">
                        <v-icon>mdi-qrcode</v-icon>
                      </v-btn>
                      <v-btn icon v-show="BtnCameraQRPulang" @click="CameraQRPulang">
                        <v-icon>mdi-scan-helper</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-divider></v-divider>

                    <div v-show="ShowCameraQRPulang" class="text-center ma-2">
                      <v-sheet outlined style="height: 400px; width: 100%;">
                        <CameraQR :StatusCamPulang="StatusCamPulang" :Kategori="Kategori" @tutup="tutup()"/>
                      </v-sheet>
                    </div>

                    <!-- <div v-show="ShowQRCodePulang" class="text-center ma-2">
                      <v-sheet outlined style="height: 400px; width: 100%;">
                        <QRCode :StatusCamPulang="StatusCamPulang"/>
                      </v-sheet>
                    </div> -->

                    <v-card-actions>
                      <v-sheet outlined class="pa-1 mx-auto rounded-pill">
                        <v-btn-toggle group v-model="ToggleBtnQRCamera" mandatory background-color="primary">
                          <v-btn @click="CameraQRPulang" class="text-capitalize rounded-xl">
                            <v-icon class="mr-2">mdi-scan-helper</v-icon>
                            Snap QR
                          </v-btn>
                          <v-btn @click="QRCodePulang" class="text-capitalize rounded-xl">
                            <v-icon class="mr-2">mdi-qrcode</v-icon>
                            QR Code
                          </v-btn>
                        </v-btn-toggle>
                      </v-sheet>
                    </v-card-actions>

                    <v-card-actions>
                      <v-btn color="error" outlined rounded text class="mx-auto text-capitalize" @click="KeluarDialogScanPulang">
                        <v-icon left>mdi-close</v-icon>
                        <span class="mr-2">Batal</span>
                      </v-btn>
                    </v-card-actions>

                    <!-- <v-btn @click="CameraQRMasuk">Camera QR</v-btn>
                    <v-btn @click="QRCodeMasuk">QRCode</v-btn> -->
                  </v-card>
                </v-dialog>

                <!-- <v-btn @click="KeluarDialogAbsen" block height="50" depressed class="mt-3 text-capitalize rounded-lg">
                  Batal
                </v-btn> -->
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="pa-1 mb-n5">
        <v-row>
          <v-col 
          v-for="loadingskeleton in 5" 
          :key="loadingskeleton" 
          cols="12" sm="6" md="4" lg="3" xl="3" v-show="loading == true" class="pa-2">
            <v-responsive class="rounded-lg">
              <v-skeleton-loader
                height="184"
                type="image"
                class="mx-auto rounded-lg"
              >
              </v-skeleton-loader>
            </v-responsive>
          </v-col>
    
        </v-row>
      </div>

      <draggable :list="list" :disabled="!enabled" group="cardmenu" @start="drag=true" @end="drag=false" class="row pa-1">
        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pa-2" v-show="loading == false" v-for="element in list" :key="element">
          <DetailKaryawan 
            v-show="element == 1 &&
            loading == false" 
            :datakaryawan ="datakaryawan" 
            :foto="foto" 
            :fotovaksin="fotovaksin"
            :vaksin="vaksin"
            :jmlanak="jmlanak" 
            :kontrak="kontrak" 
            :jabatan="jabatan" 
            :anak="anak"/>
          <DetailKontrakKaryawan v-show="element == 2 && loading == false" :datakaryawan ="datakaryawan" :foto="foto" :jmlanak="jmlanak" :kontrak="kontrak" :jabatan="jabatan"/>
          <DetailJabatanKaryawan v-show="element== 3 && loading == false" :datakaryawan ="datakaryawan" :foto="foto" :jmlanak="jmlanak" :kontrak="kontrak" :jabatan="jabatan"/>
          <AnalisisKaryawan v-show="element == 4" :status="status" :chartdata="chartdata" :options="options"/>
          <ChartTerlambat v-show="element == 5" :StatusLineTerlambatHarian="StatusLineTerlambatHarian" :chartdatabar="chartdatabar" :optionschartbar="optionschartbar"/>
          <CutiBeranda v-show="element == 6" :datakaryawan ="datakaryawan" :datacuti="datacuti" :listcuti="listcuti" :historycuti="historycuti" :kontrak="kontrak"/>
          <ListCuti v-show="element == 7" :datakaryawan ="datakaryawan" :datacuti="datacuti" :listcuti="listcuti" :kontrak="kontrak"/>
        </v-col>

      </draggable>
      <div class="row pa-1 mt-1">
        <v-col cols="12" sm="12" md="12" lg="12" xl="6" class="pa-2">
          <ChartDashboard v-show="StatusLineTerlambatHarian=='wadobang' && valid == true" :valid="valid" :loading="loading"/>
        </v-col>
      </div>

      <!-- btn pengajuan cuti karyawan beranda -->
      <!-- <v-btn @click="FormCutiKaryawanBeranda = true" fab dark x-large color="red darken-4" class="elevation-10" style="position:fixed;bottom:20px;right:20px;">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn> -->

      <!-- <v-skeleton-loader v-show="loading == true" class="rounded-pill" type="image" width="65px" height="65px" style="position:fixed;bottom:20px;right:20px;"></v-skeleton-loader>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="loading == false" to="/FormCutiKaryawan" fab dark large color="red darken-4" class="elevation-20" v-bind="attrs" v-on="on" style="position:fixed;bottom:20px;right:20px;">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span class="subtitle-1">Buat Pengajuan Cuti</span>
      </v-tooltip> -->

      <!-- <v-dialog
        v-model="FormCutiKaryawanBeranda"
        persistent
        max-width="290"
      >
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Dialog</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="FormCutiKaryawanBeranda = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            ashjefbkwefl
          </v-card-text>
        </v-card>
      </v-dialog> -->

      <!-- <v-speed-dial
        v-model="BtnfabHome"
        transition="slide-y-reverse-transition"
        style="position:fixed;bottom:20px;right:20px;"
      >
        <template v-slot:activator>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-model="BtnfabHome"
                elevation="10"
                color="red darken-4"
                x-large
                v-bind="attrs"
                v-on="on"
                dark
                fab
              >
                <v-icon v-if="BtnfabHome">mdi-close</v-icon>
                <v-icon v-else>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            <span class="subtitle-1">Buat Pengajuan Cuti</span>
          </v-tooltip>
        </template>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            v-bind="attrs"
            v-on="on"
            color="green"
            @click="BukaForm"
          >
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
          </template>
          <span>Permohonan Izin</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              v-bind="attrs"
              v-on="on"
              color="indigo"
            >
              <v-icon>mdi-message-text</v-icon>
            </v-btn>
          </template>
          <span>Message</span>
        </v-tooltip>
      </v-speed-dial> -->

      <FormCutiKaryawanBeranda v-show="valid == true && FormCutiKaryawanBeranda == false" :FormCutiKaryawanBeranda="FormCutiKaryawanBeranda" @FormCutiKaryawanBeranda="FormCutiKaryawanBerandaChild"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]

// const DetailKaryawan = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/DetailKaryawan.vue')
// const DetailKontrakKaryawan = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/DetailKontrakKaryawan.vue')
// const DetailJabatanKaryawan = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/DetailJabatanKaryawan.vue')
// const ChartDashboard = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/ChartDashboard.vue')
// const AnalisisKaryawan = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/AnalisisKaryawan.vue')
// const FormCutiKaryawan = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/FormCutiKaryawan.vue')
// const ChartTerlambat = () => import (/* webpackChunkName: "dashboard-menu"*/ '../components/ChartKeterlambatanPerday.vue')
import CameraQR from '@/components/CameraQrCode.vue';
// import QRCode from '@/components/AbsenQrCode.vue';
import Vue from 'vue';
import DetailKaryawan from '@/components/DetailKaryawan';
import DetailKontrakKaryawan from '@/components/DetailKontrakKaryawan';
import DetailJabatanKaryawan from '@/components/DetailJabatanKaryawan';
import ChartDashboard from '@/components/ChartDashboard';
import AnalisisKaryawan from '@/components/AnalisisKaryawan';
import CutiBeranda from '@/components/CutiBeranda';
import ListCuti from '@/components/ListCuti';
import FormCutiKaryawanBeranda from '@/components/FormCutiKaryawanBeranda';
import ChartTerlambat from '@/components/ChartKeterlambatanPerday';
import draggable from 'vuedraggable';

import api from "@/services/http";
export default {
  components: {
      CameraQR, DetailKaryawan, DetailKontrakKaryawan, DetailJabatanKaryawan,
      ChartDashboard,AnalisisKaryawan,CutiBeranda,ChartTerlambat,draggable,FormCutiKaryawanBeranda,ListCuti
    },
  data () {
    return {
      ToggleBtnQRCamera: false,
      BtnCameraQRMasuk: '',
      BtnQRCodeMasuk: true,
      ShowCameraQRMasuk: true,
      ShowQRCode: '',
      Kategori:null,
      CordinateUser:null,
      BtnCameraQRPulang: '',
      BtnQRCodePulang: true,
      ShowCameraQRPulang: true,
      ShowQRCodePulang: '',
      DialogScanMasuk: false,
      DialogScanPulang: false,
      DialogAbsen: false,
      absen:[],
      // StatusCamPulang:false,
      FormCutiKaryawanBeranda: false,
      settings: [],
      countDown : 15,
      // StatusCam:false,
      DialogMenyapaUser: false,
      MenuSettingHome: false,
      BtnfabHome: false,
      tgl : new Date().toISOString().slice(0,10),
      status:null,
      StatusLineTerlambatHarian:null,
      server:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.origin+'/tss/public',
      //---------https------
      // server:"https://192.168.1.189/tss/public",
      // server:"https://147.139.189.197/tss/public",
      // server:"https://trinityauto.web.id/tss/public",
      //---------http-------
      // server:"http://192.168.1.189/tss/public",
      // server:"http://"+window.location.hostname+"/tss/public",
      // server:"http://"+window.location.hostname+":8000",
      // FormCutiKaryawan:false,
      enabled: false,
      barChartData: {
        labels: ["Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec"
            
          ],
        datasets: [
          {
            label: "Terlambat",
            // data: ['0','0','0','0','0','0','0','0','0','0','0','0'],
            data: [],
            backgroundColor: [],
            borderColor: [
              "rgba(255,99,132, 1)",
              "rgba(255,99,132, 1)",
              "rgba(255,99,132, 1)",
              "rgba(255,99,132, 1)",
              "rgba(255,99,132, 1)",
              "rgba(255,99,132, 1)",
            ],
            borderWidth: 1.5
          }
        ]
      },
      chartdata: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      datasets: [
          {
            label: 'Terlambat',
            backgroundColor: '#b71c1c',
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              display: true
            },
            gridLines: {
                display:false
            }
          }],

          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
                display:false
            }
          }]
        }
      },

      chartdatabar: {
      labels: [],
      datasets: [
          {
            label: 'Terlambat',
            borderColor: "rgb(191,55,55)",
            data: []
          }
        ]
      },
      optionschartbar: {
        legend: {
            display: false
         },
        //  tooltips: {
        //     enabled: false
        //  },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              display: true
            },
            gridLines: {
                display:false
            }
          }],
          yAxes: [{
            offset: true,
            ticks: {
              display: false
            },
            gridLines: {
                display:false
            }
          }]
        }
      },
      absensi:[],
      seriesData1:[],
      seriesData:[],
      listcuti:[],
      historycuti:[],
      datacuti:{
        karyawan:{
          Nama:'',
          jabatan:'',
        },
        approvalcuti:'',
      },
      windowSize: {x: 0, y: 0},
      mobile:false,
      dialog: false,
      valid:false,
      loading:true,
      datakaryawan:{
        NAMA:'',
        NRK:'',
        POH:'',
        UserMenu:'',
      },
      list:[],
      foto:null,
      fotovaksin:null,
      jmlanak:'',
      kontrak:{
        NoKontrak:'',
        TanggalAkhir:'',
        TanggalMulai:'',
      },
      jabatan:{
        Atasan:'',
        Departemen:'',
        Divisi:'',
        KodeJabatan:'',
        KodePerusahaan:'',
        NamaJabatan:'',
      },
      anak:{
        KodeKaryawan:'',
        NamaAnak:'',
        AnakKe:'',
      },
      vaksin:{
        KodeKaryawan:'',
        VaksinKe :'',
        TanggalVaksin:'',
        Sertifikat:'',
        Keterangan:'',
      },
      dataterlambat:[],
      token:null,
      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      // value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      gradientDirection: 'top',
      gradients,
      hal:null,
      fill: false,
      type: 'trend',
      autoLineWidth: false,
    }
  },

  computed:{
    StatusAbsen(){
      return this.DialogScanMasuk == true ? 'Scan Masuk' : this.DialogScanPulang == true ? 'Scan Pulang' : null
    },
    StatusCam(){
      return this.DialogScanMasuk == true ? true : false
    },
    StatusCamPulang(){
      return this.DialogScanPulang == true ? true : false
    }
  },

  watch:{
    hal(){
      // console.log(this.hal)
    },
    StatusCam(){
      console.log('sss',this.StatusCam)
    },
    StatusCamPulang(){
      console.log('xxx',this.StatusCamPulang)
    },
    // DialogScanMasuk(){
    //   if(this.DialogScanMasuk == true){
    //     this.StatusCam = true
    //   }else{
    //     this.StatusCam = false
    //   }
    // },
    // DialogScanPulang(){
    //   if(this.DialogScanPulang == true){
    //     this.StatusCamPulang = true
    //   }else{
    //     this.StatusCamPulang = false
    //   }
    // },
    StatusAbsen(){
      Vue.prototype.$StatusAbsen = this.StatusAbsen
    },
    enabled(){
      if (this.enabled == true && this.mobile == true) {
        document.getElementById("toolbar").style.position = "fixed";
        document.getElementById("toolbar").style.top = "60px";
        document.getElementById("toolbar").style.width = "342px";
        document.getElementById("toolbar").style.zIndex = "1";
        document.getElementById("toolbar").style.opacity = "0.8";
      }else if (this.enabled == false && this.mobile == true) {
        document.getElementById("toolbar").style.removeProperty('position')
        document.getElementById("toolbar").style.removeProperty('top')
        document.getElementById("toolbar").style.removeProperty('width')
        document.getElementById("toolbar").style.removeProperty('zIndex')
        document.getElementById("toolbar").style.removeProperty('opacity')
      }
    },
    windowSize(){
      if (this.windowSize.x < 600) {
        this.mobile = true
      }else{
        this.mobile = false
      }
    },
    status(){
      // if (this.status == true) {
      //   document.getElementById("chart").style.visibility = "visible";
      // }
    },
    list(){
      if (this.list == null) {
        //do nothing
      }else{
      localStorage.setItem('list',JSON.stringify(this.list))
      this.updateDashboard()
      }
      
    }
  },
  created(){
    //
  },
  
  mounted(){    
    const theme = localStorage.getItem("darktheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        // this.switchModeMalam = this.$vuetify.theme.dark;
      } else {
        this.$vuetify.theme.dark = false;
        // this.switchModeMalam = this.$vuetify.theme.dark;
      }
    }
    this.getLocationUser()
    Vue.prototype.$getJarak = this.getJarak()
    // document.getElementById("chart").style.visibility = "visible";
    // this.token = localStorage.getItem('token')
    // this.getdata()
    // console.log(this.$refs.okok)
  },
  methods:{
    tutup(v){
      console.log(v)
      // let data = this.$ScanMasukToday != null ? this.$ScanMasukToday : this.$ScanPulangToday != null ? this.$ScanPulangToday : null
        this.DialogScanPulang = false
        this.DialogScanPulang = false
        if(this.$ScanMasukToday != null && this.absen.length == 0){
          this.absen.push(this.$ScanMasukToday)
        }
        if(this.$ScanPulangToday != null && this.absen.length != 0){
          this.absen.push(this.$ScanPulangToday) 
        }
        this.Toast('Terima Kasih')
      this.DialogAbsen = false
    },
    ScanMasuk(){
      this.Kategori = this.datakaryawan.kategoriabsen
      // this.StatusCam = true
    },
    ScanPulang(){
      this.Kategori = this.datakaryawan.kategoriabsen
      // this.StatusCamPulang = true
    },
    ChangeStatusCam(){
      // this.DialogScanMasuk = v
      this.StatusCam = false
      // this.$emit('Clicked',false)
    },
    getLocationUser(){
        var options = {
      enableHighAccuracy: true,
      timeout: 20000,
      maximumAge: 0
    };
    function success(pos) {
      var crd = pos.coords;
      // console.log('Latitude :', crd.latitude);
      // console.log('Longitude : ', crd.longitude);
      sessionStorage.setItem('lat',crd.latitude)
      sessionStorage.setItem('long',crd.longitude)
      // console.log(`Latitude : ${crd.latitude}`);
      // console.log(`Longitude: ${crd.longitude}`);
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.watchPosition(success, error, options);
    // this.setCordinate()
    Vue.prototype.$latitude = sessionStorage.getItem('lat') //set kordinat latitude jadi global
    Vue.prototype.$longitude = sessionStorage.getItem('long') //set kordinat longitude jadi global
    },
    CameraQRMasuk() {
      console.log(this.getJarak(this.$latitude,this.$longitude,-0.5146435,117.1310156))
      this.ShowCameraQRMasuk = true
      this.ShowQRCode = false
      this.BtnQRCodeMasuk = true
      this.BtnCameraQRMasuk = false
    },

    QRCodeMasuk() {
      this.ShowCameraQRMasuk = false
      this.ShowQRCode = true
      this.BtnQRCodeMasuk = false
      this.BtnCameraQRMasuk = true
    },

    CameraQRPulang() {
      this.ShowCameraQRPulang = true
      this.ShowQRCodePulang = false
      this.BtnQRCodePulang = true
      this.BtnCameraQRPulang = false
    },

    QRCodePulang() {
      this.ShowCameraQRPulang = false
      this.ShowQRCodePulang = true
      this.BtnQRCodePulang = false
      this.BtnCameraQRPulang = true
    },

    getJarak(lat1,lon1,lat2,lon2) {
          function deg2rad(deg) {
            return deg * (Math.PI/180)
          }
        var R = 6371; // Radius of the earth in km
        var KmToMeters = 1000; // Km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c * KmToMeters; // Distance in meters
        d = parseInt(d)
        return d;
      },

    KeluarDialogAbsen() {
      this.DialogAbsen = false
    },

    KeluarDialogScanMasuk() {
      this.DialogScanMasuk = false
    },

    KeluarDialogScanPulang() {
      this.DialogScanPulang = false
    },

    toggle_dark_mode() { 
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darktheme", this.$vuetify.theme.dark.toString());
      console.log('dark mode',this.$vuetify.theme.dark);    
    },

    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    MenyapaUser(data){
      if(sessionStorage.getItem('greeting') == 0){
        this.Toast('Halo '+data.Nama+' Welcome')
        this.DialogMenyapaUser = true
        this.datakaryawan.Nama

        setTimeout(() => {
          this.DialogMenyapaUser = false
        }, 5000);
        sessionStorage.setItem('greeting',1)
      }
    },
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      showCloseButton: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true})
    },
    updateDashboard(){
      let user = JSON.parse(localStorage.getItem('user'))
      let a = JSON.stringify(this.list)
      let data = a.replace(/[&\\#+()$~%.'":*?<>{}]/g, '').replace('[','').replace(']','')
      // console.log('tes',data)
      api.put("/updateDashboard/"+user.Kode+'?token='+this.token,{MenuDashboard: data,},
          // { headers: {"Authorization" : `Bearer ${this.token}` } }
          )
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              // this.AlertBerhasilTerubah = true
						}
						
					})
    },
    FormCutiKaryawanBerandaChild(data){
     this.FormCutiKaryawanBeranda = data
    },
    BukaForm(){
          this.FormCutiKaryawanBeranda = true
    },
    random_rgba() {
          return "hsla(" + ~~(360 * Math.random()) + "," +"70%,"+"80%,1)"
    },
    formattanggal(x) {
          var todayTime = new Date(x);
          var bulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];
          var month = bulan[todayTime .getMonth()];
          var day = todayTime .getDate();
          // var year = todayTime .getFullYear();
          // return day + " " + month + " " + year;  
          return day + " " + month;  
    },
    formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
    },
    getabsen(v1,v2){
      let final = []
      if(v1 != null){
        if(v1.ScanMasuk != null && v1.ScanPulang == null){
          v1.Scan = 'Scan Masuk'
          final.push(v1)
        }else if(v1.ScanMasuk != null && v1.ScanPulang != null){
          v1.Scan = 'Scan Masuk'
          v2.Scan = 'Scan Pulang'
          final.push(v1,v2)
        }
      }
      console.log([v1,v2])
        // if (v1 != null) {
        //   if (v1.ScanPulang == null) {
        //     final.push(v1)
        //     final[0].Scan = 'Scan Masuk'
        //   }else{
        //     final.push(v1,v2)
        //     final[0].Scan = 'Scan Masuk'
        //     final[1].Scan = 'Scan Pulang'
        //   }
        // }
      return final
    },
    dataperday(){
        let today=new Date(), y = today.getFullYear(), m = today.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        let tgl = this.formatDate(firstDay)
        let tglskrng = this.formatDate(lastDay)
      api.get('/analisisterlambat/'+tgl+'/'+tglskrng+'/?token='+this.token).then(
				res => {
          this.seriesData1 = res.data
          console.log('resssxx',res.data)
          for (let index = 0; index < this.seriesData1.length; index++) {
                // if(!this.seriesData1[index].terlambat){
                //   //do nothing
                // }else{
                  this.seriesData1[index].tanggal = this.formattanggal(this.seriesData1[index].tanggal)
                  // this.seriesData1[index].warna = this.random_rgba()
              // }
            }
          if (this.seriesData1.length == 0) {
            this.seriesData = []
          }else{
            this.seriesData = this.seriesData1
            // console.log('wowo',this.seriesData)
            let datatanggal = []
            let dataterlambat = []
            let warna = []
            for (let index = 0; index < this.seriesData.length; index++) {
              //tanggal
              const elementtanggal = datatanggal.concat(this.seriesData[index].tanggal);
              const elementtanggalfix = elementtanggal[elementtanggal.length-1]
              datatanggal.push(elementtanggalfix)
              //datanya
              const elementterlambat = dataterlambat.concat(this.seriesData[index].terlambat)
              const elementterlambatfix = elementterlambat[elementterlambat.length-1]
              dataterlambat.push(elementterlambatfix)
              //warnanya
              const elementwarna = warna.concat(this.seriesData[index].warna)
              const elementwarnafix = elementwarna[elementwarna.length-1]
              warna.push(elementwarnafix)
            }
            this.chartdatabar.labels = datatanggal
            this.chartdatabar.datasets[0].data = dataterlambat
            // this.chartdatabar.datasets[0].backgroundColor = warna
            this.StatusLineTerlambatHarian = true
            // console.log(this.chartdata)
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },
  },
  beforeMount(){
    this.token = localStorage.getItem('token')
    api.get('/saya?token='+this.token,
    // { headers: {"Authorization" : `Bearer ${this.token}`} }
    ).then(
        res=>{
          if (res.data.KodeKaryawan != null) {
              this.datakaryawan = res.data
              this.MenyapaUser(res.data)
              this.valid = true
              this.anak = res.data.anak
              this.jmlanak = res.data.anak.length
              this.kontrak = res.data.kontrak.length > 0  ? res.data.kontrak[res.data.kontrak.length-1] : []
              this.jabatan = res.data.jabatan
              this.vaksin = res.data.vaksin.length > 0 ? res.data.vaksin : this.vaksin
              this.user = res.data.user
              this.absensi = res.data.absensi
              this.absen = this.getabsen(res.data.absensi,res.data.absensi)
              this.foto = this.server+res.data.Photo
              // this.foto = "http://"+window.location.hostname+":8000"+res.data.Photo
              this.fotovaksin = this.server+this.vaksin.Sertifikat
              // this.fotovaksin = "http://"+window.location.hostname+":8000"+this.vaksin.Sertifikat
              // api.get('/terlambatbulanan/'+this.tgl+'/'+this.datakaryawan.NRK ,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              api.get('/terlambatbulanan/'+this.tgl+'/'+this.datakaryawan.NRK+'?token='+this.token).then(
                res => {
                  let result = res.data[0]
                  let data = []
                  let finaldata = data.concat(result.Jan,result.Feb,result.Mar,result.Apr,result.May,result.Jun,result.Jul,result.Agu,result.Sep,result.Okt,result.Nov,result.Dec)
                  this.chartdata.datasets[0].data = finaldata
                  this.dataperday()
                  this.status = true
                  // console.log('final',this.chartdata)
                },
                err => {
                  console.log(err);
                }
              )
              api.post('/cutilist/?token='+this.token,{
                KodeKaryawan:this.datakaryawan.KodeKaryawan
              }).then(
                res => {
                  // console.log(res.data)
                  this.datacuti = res.data
                },
                err => {
                  console.log(err);
                }
              )
              api.get('/listcuti/?token='+this.token).then(
                res => {
                  // console.log(res.data)
                  this.listcuti = res.data
                },
                err => {
                  console.log(err);
                }
              )
              api.get('/historycuti/'+this.datakaryawan.KodeKaryawan+'?token='+this.token).then(
                res => {
                  console.log(res.data)
                  this.historycuti = res.data
                },
                err => {
                  console.log(err);
                }
              )
              this.loading = false
            //do noting
            // this.$refs.detailkaryawan.getdata()
          }else{
            this.datakaryawan.visible = true
            this.kontrak.visible = true
            this.jabatan.visible = true
            this.loading = false
          }

          if (res.data.MenuDashboard == null) {
              // lists = [{no:1},{no:2},{no:3},{no:4}]
              // lists = [1,2,3,4]
              // localStorage.setItem('list',JSON.stringify(lists))
              if (!this.datakaryawan.visible) {
                // this.list = new Array(1,2,3,4)
                this.list = new Array()
                  this.list.push(1,2,3,4,6)
                  // if (this.kontrak.length != 0) { this.list.push(2) }
                  // if (this.jabatan.length != 0) { this.list.push(3) }
                  // this.list.push(4)
                this.list = [...this.list]
              }else{
                this.list = null
              }
              
            }else{
              // console.log('awokwok',res.data.PosisiDashboard)
              if (res.data.MenuDashboard.length == 1) {
                let a = res.data.MenuDashboard[0]
                this.list = a
              }else{
               this.list = res.data.MenuDashboard
              }
            }
        })
      
  }
  
}
</script>

<style scoped>

</style>
