<template>
  <div v-resize="onResize">
    <v-card 
      rounded="lg"
      class="mx-auto"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 163px;">
        <div class="text-center caption pa-2 mb-4" v-text="'Keterlambatan Harian Periode '+periode"></div>
        <ChartBar 
        :width="199" 
        :height="103" 
        :chartdatabar="chartdatabar" 
        :optionschartbar="optionschartbar" 
        :StatusLineTerlambatHarian="StatusLineTerlambatHarian"/>
      </div>
    </v-card>

    <!-- <v-card 
    outlined 
    height="177" class="rounded-lg pa-2 elevation-3" :color="$vuetify.theme.dark ? 'dark' : 'dark'"
    v-show="this.chartdatabar">
      <div class="mt-n2 mb-1 text-center text-truncate">
        <u><small v-text="'Keterlambatan Harian Periode '+periode"></small></u>
        <v-icon right small>mdi-chart-bar</v-icon>
      </div>
      <ChartBar 
      :width="300" 
      :height="142" 
      :chartdatabar="chartdatabar" 
      :optionschartbar="optionschartbar" 
      :StatusLineTerlambatHarian="StatusLineTerlambatHarian"/>
    </v-card> -->

  </div>
</template>

<script>
// const ChartBar = () => import (/* webpackChunkName: "chart-bar"*/ '../components/BaseChartBarDashboard.vue')
import ChartBar from "@/components/BaseChartBarDashboard.vue"
// import api from "@/services/http";
// import { mdbBarChart, mdbContainer } from "mdbvue";
export default {
  props: ['chartdatabar', 'optionschartbar','StatusLineTerlambatHarian'],
  components: {
    ChartBar,
  },

  data () {
    return {
      windowSize: {x: 0, y: 0},
      mobile:null,
      periode: new Date().getFullYear(),
      loaded: true,
    }
  },
  
  watch:{
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  mounted(){

  },

  computed: {

  },


  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>