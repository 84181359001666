<template>
	<div>
    <!-- <v-toolbar flat dense outlined height="44" class="rounded-lg mb-4">
    </v-toolbar> -->
    <v-card
      outlined
      v-resize="onResize"
      class="rounded-lg elevation-3"
    >
      <div>
        <!-- Mode Desktop Header Chart Dashboard -->
        <div>
          <v-card-actions> 
            <!-- Skeleton Loader Filter Tanggal -->
            <!-- <div v-show="loading == true">
              <v-skeleton-loader
                width="160"
                height="35"
                type="image"
              ></v-skeleton-loader>
            </div> -->
            
            <!-- Filter Tanggal Mode Mobile -->
            <div v-show="loading == false">
              <v-menu
                transition="scroll-y-transition"
                v-model="MenuFilterTanggal"
                :close-on-content-click="false"
                :nudge-width="70"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="MenuFilterTanggal = !MenuFilterTanggal"
                    class="text-capitalize mr-n1"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    text
                  >
                    <v-icon class="ml-n2 mr-2">mdi-calendar-multiple</v-icon>
                    <span class="subtitle-1 font-weight-medium">Filter Tanggal</span>
                    <v-icon class="ml-1 mr-n2">{{ MenuFilterTanggal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </template>
                <v-card
                  outlined
                  v-show="MenuFilterTanggal"
                  class="rounded-lg elevation-20"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="MenuFilterTanggal=false">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-list>
                    <v-list-item>
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        label="Dari Tanggal"
                        class="rounded-lg"
                        color="dark"
                        type="date"
                        v-model="daritanggal"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-list-item>
                    <v-list-item>
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        label="Sampai Tanggal"
                        class="rounded-lg mb-n2"
                        color="dark"
                        type="date"
                        v-model="sampaitanggal"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </v-card-actions>
        </div>

        <div class="text-center title ma-4">
          <p>Jumlah keterlambatan dari :
            <v-chip class="subtitle-1">{{daritanggal}}</v-chip> - <v-chip class="subtitle-1">{{sampaitanggal}}</v-chip>
          </p>
        </div>

        <v-divider></v-divider>

        <div class="row pa-1 mt-1">
          <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mb-4">
            <BaseChartPieDashboard v-if="valid == true" ref="chartpie" :status="status" :chartdata="chartdata" :options="options"></BaseChartPieDashboard>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mb-4">
            <BaseChartBarDashboard v-if="valid == true" ref="chartbar" :status="status" :chartdatabar="chartdatabar" :optionschartbar="optionschartbar"></BaseChartBarDashboard>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-4" v-if="valid == null">
            <div class="text-center mb-4 mt-n3"><v-icon x-large color="error">mdi-alert</v-icon></div>
            <p class="display-1 text-center">Data Tidak Ditemukan</p>
            <!-- <v-alert text type="error">
              Data Tidak Kami Temukan
            </v-alert> -->
          </v-col>
        </div>
        <!-- <v-btn @click="pieclick">pie</v-btn>
        <v-btn @click="barclick">bar</v-btn> -->
        
        <!-- <v-card outlined class="rounded-lg"> -->
          <!-- <ejs-chart width='1000' height='350px' id="container" :title='title' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :tooltip='tooltip'>
            <e-series-collection>
              <e-series :dataSource='seriesData' type='Column' xName='tanggal' yName='terlambat' name='Terlambat' :marker='marker' fill="grey"> </e-series>
            </e-series-collection>
          </ejs-chart> -->
          <!-- <ChartPieDashboard v-if="pie == true" :pie="pie" :MenuFilterTanggal="MenuFilterTanggal" :daritanggal="daritanggal" :sampaitanggal="sampaitanggal"></ChartPieDashboard> -->
          
        <!-- </v-card>
        <v-card> -->
          <!-- <ChartBarDashboard v-if="bar == true" :bar="bar" :MenuFilterTanggal="MenuFilterTanggal" :daritanggal="daritanggal" :sampaitanggal="sampaitanggal"></ChartBarDashboard> -->
        <!-- </v-card> -->
      </div>
    </v-card>
	</div>
</template>

<script>
// import Vue from "vue";
// import ChartBarDashboard from "@/components/ChartBarDashboard.vue"
import BaseChartPieDashboard from "@/components/BaseChartPieDashboard.vue"
import BaseChartBarDashboard from "@/components/BaseChartBarDashboard.vue"
// import ChartPieDashboard from "@/components/ChartPieDashboard.vue"
import api from "@/services/http";


export default {
  props:['loading','valid'],
  components: {
      // ChartPieDashboard,
      BaseChartPieDashboard,
      BaseChartBarDashboard,
      // ChartBarDashboard
    },
  data() {
return {
  pie:true,
  bar:false,
  status:null,
  mobile:null,
  windowSize: {x: 0, y: 0},
  MenuFilterTanggal: false,
  seriesData1:[],
  token:null,
  daritanggal:null,
  sampaitanggal:null,
  seriesData: [],
  chartdata: {
      labels: [],
      datasets: [
          {
            label: 'Terlambat',
            // backgroundColor: 'rgba(157,38,154,0.6)',
            backgroundColor:[],
            data: []
          }
        ]
      },
      options: {
        // legend: {
        // display: false
        // },
        responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //   yAxes: [{
        //     ticks: {
        //       display: false
        //     },
        //     gridLines: {
        //         display:false
        //     }
        //   }]
        // }
      },

      chartdatabar: {
      labels: [],
      datasets: [
          {
            label: 'Terlambat',
            backgroundColor: '#ffcdd2',
            // backgroundColor:[],
            data: []
          }
        ]
      },
      optionschartbar: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            offset: true,
            ticks: {
              display: false
            },
            gridLines: {
                display:false
            }
          }]
        }
      },

    primaryXAxis: {
      valueType: 'DateTime',
      labelFormat: 'dd-MMM',
      rangePadding: 'Additional',
    },
    border: {
        color: 'green',
        width: 2
    },
    primaryYAxis:{
        labelFormat: '{value} 👷Karyawan',
    },
    legendSettings: {
        visible: true
    },
    marker: {
    dataLabel:{
            visible: true, width: 10, height: 10
        }
    },
    tooltip: { enable: true, header: 'Terlambat'},
  title: "Analisis Keterlambatan"
};
  },
//   provide: {
// chart: [LineSeries, DateTime,ColumnSeries,AreaSeries ,BubbleSeries ,BarSeries,ScatterSeries  , DataLabel, Tooltip]
//   },
  watch:{
    MenuFilterTanggal(){
      if (this.daritanggal !=null && this.sampaitanggal != null && this.MenuFilterTanggal == false) {
        this.getdata()
      }
    },

    windowSize(){
      if (this.windowSize.x < 800) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },
  mounted(){
    // 
  },
  beforeMount(){
    this.token = localStorage.getItem('token')
    // this.getdata()
    this.data7day()
  },
  methods:{
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    
    pieclick(){
      this.pie = false
      this.bar = true
        this.$nextTick(() => {
          this.pie = true
          this.bar = false
        });
    },
    barclick(){
      this.bar = false
      this.pie = true
      this.$nextTick(() => {
          this.bar = true
          this.pie = false
        });
    },
    data7day(){
      function formattanggal(x) {
          var todayTime = new Date(x);
          var bulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];
          var month = bulan[todayTime .getMonth()];
          var day = todayTime .getDate();
          var year = todayTime .getFullYear();
          return day + " " + month + " " + year;  
      }
        let today=new Date();
        const days_to_subtract=7;
        let tgl= new Date(today.valueOf()-(days_to_subtract*24*60*60*1000)).toISOString().slice(0,10);
        let tglskrng = today.toISOString().slice(0,10)
        this.daritanggal = tgl
        this.sampaitanggal = tglskrng
      function random_rgba() {
          // var o = Math.round, r = Math.random, s = 255;
          // return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
          return "hsla(" + ~~(360 * Math.random()) + "," +
                    "70%,"+
                    "80%,1)"
          // var num = Math.round(0xffffff * Math.random());
          // var r = num >> 16;
          // var g = num >> 8 & 255;
          // var b = num & 255;
          // return 'rgb(' + r + ', ' + g + ', ' + b + ')';
      }    

      api.get('/analisisterlambat/'+tgl+'/'+tglskrng+'/?token='+this.token ,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
          this.seriesData1 = res.data
          for (let index = 0; index < this.seriesData1.length; index++) {
                if(!this.seriesData1[index].terlambat){
                  //do nothing
                }else{
                  this.seriesData1[index].tanggal = formattanggal(this.seriesData1[index].tanggal)
                  this.seriesData1[index].warna = random_rgba()
              }
            }
          if (this.seriesData1.length == 0) {
            this.seriesData = []
          }else{
            this.seriesData = this.seriesData1
            // console.log('wowo',this.seriesData)
            let datatanggal = []
            let dataterlambat = []
            let warna = []
            for (let index = 0; index < this.seriesData.length; index++) {
              //tanggal
              const elementtanggal = datatanggal.concat(this.seriesData[index].tanggal);
              const elementtanggalfix = elementtanggal[elementtanggal.length-1]
              datatanggal.push(elementtanggalfix)
              //datanya
              const elementterlambat = dataterlambat.concat(this.seriesData[index].terlambat)
              const elementterlambatfix = elementterlambat[elementterlambat.length-1]
              dataterlambat.push(elementterlambatfix)
              //warnanya
              const elementwarna = warna.concat(this.seriesData[index].warna)
              const elementwarnafix = elementwarna[elementwarna.length-1]
              warna.push(elementwarnafix)
            }
            this.chartdata.labels = datatanggal
            this.chartdata.datasets[0].data = dataterlambat
            this.chartdata.datasets[0].backgroundColor = warna
            this.chartdatabar.labels = datatanggal
            this.chartdatabar.datasets[0].data = dataterlambat
            // this.chartdatabar.datasets[0].backgroundColor = warna
            this.status = true
            // console.log(this.chartdata)
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },
    getdata(){
      this.status = false
      function formattanggal(x) {
          var todayTime = new Date(x);
          var bulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];
          var month = bulan[todayTime .getMonth()];
          var day = todayTime .getDate();
          var year = todayTime .getFullYear();
          return day + " " + month + " " + year;  
      }
      function random_rgba() {
          // var o = Math.round, r = Math.random, s = 255;
          // return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
          // return "hsl(" + ~~(360 * Math.random()) + "," +
          //           "70%,"+
          //           "80%,1)"
          // return "hsl(" + 360 * Math.random() + ',' +
          //    (25 + 70 * Math.random()) + '%,' + 
          //    (85 + 10 * Math.random()) + '%)'
          return "hsla(" + ~~(360 * Math.random()) + "," +
                    "70%,"+
                    "80%,1)"
          // var num = Math.round(0xffffff * Math.random());
          // var r = num >> 16;
          // var g = num >> 8 & 255;
          // var b = num & 255;
          // return 'rgb(' + r + ', ' + g + ', ' + b + ')';
      }    

      api.get('/analisisterlambat/'+this.daritanggal+'/'+this.sampaitanggal+'/?token='+this.token ,
      // { headers: {"Authorization" : `Bearer ${this.token}`} }
      ).then(
				res => {
          this.seriesData1 = res.data
          this.loading = false
          for (let index = 0; index < this.seriesData1.length; index++) {
                if(!this.seriesData1[index].terlambat){
                  //do nothing
                }else{
                  this.seriesData1[index].tanggal = formattanggal(this.seriesData1[index].tanggal)
                  this.seriesData1[index].warna = random_rgba()
              }
            }
          if (this.seriesData1.length == 0) {
            this.seriesData = []
            this.valid = null
          }else{
            this.valid = true
            this.seriesData = this.seriesData1
            // console.log('wowo',this.seriesData)
            let datatanggal = []
            let dataterlambat = []
            let warna = []
            for (let index = 0; index < this.seriesData.length; index++) {
              //tanggal
              const elementtanggal = datatanggal.concat(this.seriesData[index].tanggal);
              const elementtanggalfix = elementtanggal[elementtanggal.length-1]
              datatanggal.push(elementtanggalfix)
              //datanya
              const elementterlambat = dataterlambat.concat(this.seriesData[index].terlambat)
              const elementterlambatfix = elementterlambat[elementterlambat.length-1]
              dataterlambat.push(elementterlambatfix)
              //warnanya
              const elementwarna = warna.concat(this.seriesData[index].warna)
              const elementwarnafix = elementwarna[elementwarna.length-1]
              warna.push(elementwarnafix)
            }
            this.chartdata.labels = datatanggal
            this.chartdata.datasets[0].data = dataterlambat
            this.chartdata.datasets[0].backgroundColor = warna
            this.chartdatabar.labels = datatanggal
            this.chartdatabar.datasets[0].data = dataterlambat
            // this.chartdatabar.datasets[0].backgroundColor = warna
            this.status = true
            // console.log(this.chartdata)
          }
          
				},
				err => {
					console.log(err);
				}
			)
    },

  },
};
</script>
<style>
 /* #container {
   height: 450px;
 } */
</style>