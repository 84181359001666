<template>
  <div v-resize="onResize">
    <!-- Card Mode Desktop Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == false && !this.datakaryawan.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 110px;">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Karyawan</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.Nama"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.NRK"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.Lokasi"></v-list-item-subtitle>
          </v-list-item-content>

          <v-hover v-slot="{ hover }">
            <v-list-item-avatar
              size="45px"
              class="rounded-lg mt-4 ml-4"
              :class="{ 'on-hover': hover }"
              :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
            >
              <v-responsive :aspect-ratio="1/1">
                <v-img outlined :src="foto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-3'">mdi-account</v-icon>
                    </v-row>
                  </template>
                  <div class="align-self-center mx-auto">
                    <v-btn
                      text
                      height="45"
                      width="45"
                      large
                      class="mx-n4 rounded-lg"
                      :class="{ 'show-btns': hover }"
                      :color="transparent"
                      @click="DialogLihatFoto = true"
                    >
                      <v-icon
                        :class="{ 'show-btns': hover }"
                        :color="transparent"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </v-img>
              </v-responsive>
            </v-list-item-avatar>
          </v-hover>
        </v-list-item>
      </div>

      <v-card-actions> 
        <v-btn
          depressed
          @click="DialogDetailKaryawan = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>Detail</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
        </v-btn> 
      </v-card-actions>
    </v-card>
    <!-- Card Mode Desktop End -->

    <!-- Card Mode Mobile Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == true && !this.datakaryawan.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 185px;">  
        <v-card-actions>    
          <v-avatar
            size="55px"
            class="rounded-lg ma-2 mb-n2"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-2'"
          >
            <v-responsive :aspect-ratio="1/1">
              <v-img outlined :src="foto" @click="DialogLihatFoto = true">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon large :color="$vuetify.theme.dark ? 'error' : 'grey darken-3'">mdi-account</v-icon>
                  </v-row>
                </template>
              </v-img>
            </v-responsive>
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="DialogDetailKaryawan = true"
            class="text-capitalize rounded-lg mr-2">
            <span>Detail</span>
          </v-btn> 
        </v-card-actions>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Karyawan</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.Nama"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.NRK"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="datakaryawan.Lokasi"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <!-- Card Mode Mobile end -->

    <!-- Dialog Detail Karyawan -->
    <v-dialog
      scrollable
      max-width="1000"
      v-model="DialogDetailKaryawan"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
    >
      <v-card
        outlined
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
      >
        <v-toolbar flat>
          <v-btn icon v-show="mobile == true" @click="DialogDetailKaryawan = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title :class="mobile ? 'title mx-n4' : 'text-h5 mx-n4'">Detail Karyawan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon v-show="mobile == false" @click="DialogDetailKaryawan = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <!-- Mode Desktop -->
        <v-card-text
          v-show="mobile == false"
        >  
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet
              outlined
              class="rounded-lg pa-5"
            >
              <v-row no-gutters>
                <div style="width: 78%;">
                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Nama</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Nama"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">NRK</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.NRK"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">POH</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.POH"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Lokasi</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Lokasi"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Cabang</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Cabang"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal Masuk</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.TglMasuk"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Masa Kerja</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="MasaKerja+ ' Hari'"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tempat Lahir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.TempatLahir"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal Lahir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.TglLahir"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Alamat KTP</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.AlamatKTP"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Alamat Sekarang</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.AlamatSekarang"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Telp</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.NoTlp"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Telp Emergency</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.NoTlpEmergency"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Jenis Kelamin</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.JenisKelamin"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Pendidikan Terakhir</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.PendidikanTerakhir"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Jurusan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Jurusan"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Agama</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Agama"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Suku</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Suku"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Rek. Tabungan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.NoRekTabungan"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Kartu BPJS TK</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        x-small
                        class="mt-1"
                        style="margin-right: 10px;"
                        @click="DialogLihatKartuBPJSTK = true"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto;" v-text="datakaryawan.NoKartuBPJSTK"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. Kartu BPJS KES</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        x-small
                        class="mt-1"
                        @click="DialogLihatKartuBPJSKES = true"
                        style="margin-right: 10px;"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto;" v-text="datakaryawan.NoKartuBPJSKes"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. KTP</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        x-small
                        class="mt-1"
                        @click="DialogLihatKTP = true"
                        style="margin-right: 10px;"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto%;" v-text="datakaryawan.NoKTP"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">No. NPWP</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        x-small
                        class="mt-1"
                        @click="DialogLihatKartuNPWP = true"
                        style="margin-right: 10px;"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto;" v-text="datakaryawan.NoNPWP"></label>
                    </v-row>
                  </div>  

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Golongan Darah</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.GolDarah"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Status</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.StatusPerkawinan"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Status Kerja</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.StatusKerja"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Ukuran Baju</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.UkuranBaju"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Ukuran Sepatu</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.UkuranSepatu"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Tanggal PHK</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.TglPHK"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Keterangan</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.Keterangan"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Nama Istri Suami</label>
                      <label style="width: 2%;">:</label>
                      <label style="width: 65%;" v-text="datakaryawan.NamaIstriSuami"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Anak</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        @click="DialogLihatDetailAnak = true"
                        style="margin-right: 10px;"
                        x-small
                        class="mt-1"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto;" v-text="jmlanak"></label>
                    </v-row>
                  </div>

                  <div>
                    <v-row no-gutters class="subtitle-1">
                      <label style="width: 33%;">Vaksin</label>
                      <label style="width: 2%;">:</label>
                      <v-btn
                        @click="DialogLihatDetailVaksin = true"
                        style="margin-right: 10px;"
                        x-small
                        v-show="datakaryawan.vaksin.length > 0 ? true : false"
                        class="mt-1"
                        :outlined="$vuetify.theme.dark ? true : false"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                      <label style="width: auto;" v-text="datakaryawan.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'"></label>
                      
                    </v-row>
                  </div>
                </div>

                <div style="width: 22%;">
                  <div>
                    <v-card tile outlined class="pa-1 elevation-4">
                      <v-card
                        tile
                        outlined
                        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                        class="fill-height"
                      >
                        <v-responsive :aspect-ratio="3/4.3">
                          <v-img
                            v-if="foto"
                            :src="foto"
                            contain
                            class="d-flex flex-row fill-height"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <!-- <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular> -->
                                <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                                <span class="subtitle-1">no image</span>
                              </v-row>
                            </template>
                          </v-img>
                        </v-responsive>  
                      </v-card>
                    </v-card>
                  </div>
                </div>
              </v-row>
            </v-sheet>
          </div>
        </v-card-text>

        <!-- Mode Mobile -->
        <v-card-text
          v-show="mobile == true"
          class="ScrollCSSMobile"
          :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
        >
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg">
              <div v-show="viewdatakaryawan == true">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Nama</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Nama"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar @click="DialogLihatFoto = true" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                      <v-img :src="foto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-icon>mdi-image</v-icon>
                          </v-row>
                        </template>
                      </v-img> 
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>NRK</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NRK"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>POH</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.POH.length"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Lokasi</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Lokasi"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Tanggal Masuk</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.TglMasuk"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Masa Kerja</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="MasaKerja+ ' Hari'"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Tempat Lahir</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.TempatLahir"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Tanggal Lahir</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.TglLahir"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Alamat KTP</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.AlamatKTP"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Alamat Sekarang</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.AlamatSekarang"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>No. Telp</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoTlp"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>No. Telp Emergency</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoTlpEmergency"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Jenis Kelamin</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.JenisKelamin"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Pendidikan Terakhir</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.PendidikanTerakhir"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Jurusan</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Jurusan"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Agama</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Agama"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Suku</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Suku"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>No. Rek. Tabungan</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoRekTabungan"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatKartuBPJSTK = true">
                    <v-list-item-title><strong>No. Kartu BPJS TK</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoKartuBPJSTK"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="DialogLihatKartuBPJSTK = true" text small class="mr-n1">
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatKartuBPJSKES = true">
                    <v-list-item-title><strong>No. Kartu BPJS KES</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoKartuBPJSKes"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="DialogLihatKartuBPJSKES = true" text small class="mr-n1">
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatKTP = true">
                    <v-list-item-title><strong>No. KTP</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoKTP"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="DialogLihatKTP = true" text small class="mr-n1">
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatKartuNPWP = true">
                    <v-list-item-title><strong>No. NPWP</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NoNPWP"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="DialogLihatKartuNPWP = true" text small class="mr-n1">
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Golongan Darah</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.GolDarah"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Status</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.status"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Status Kerja</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.StatusKerja"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Ukuran Baju</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.UkuranBaju"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Ukuran Sepatu</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.UkuranSepatu"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>PHK</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.PHK"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Tanggal PHK</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.TglPHK"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Keterangan</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Keterangan"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title><strong>Nama Istri Suami</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.NamaIstriSuami"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatDetailAnak = true">
                    <v-list-item-title><strong>Anak</strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="jmlanak"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      small
                      class="mr-n1"
                      @click="DialogLihatDetailAnak = true"
                    >
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content @click="DialogLihatDetailVaksin = true">
                    <v-list-item-title><strong>Vaksin</strong></v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="subtitle-1" v-text="datakaryawan.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'"></span>
                      <v-icon class="ml-1" color="success" small v-text="datakaryawan.vaksin.length == 0 ? '' : 'mdi-checkbox-marked-circle'"></v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="DialogLihatDetailVaksin = true" text small class="mr-n1">
                      <strong>Lihat</strong>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>

              <div v-show="viewfotokaryawan == true">
                <v-layout justify-center v-show="mobile == true">
                  <v-card outlined class="pa-1 elevation-3">
                    <v-card
                      outlined
                      color="grey lighten-2"
                      class="fill-height"
                    >
                      <v-responsive :aspect-ratio="3/4">
                        <v-img
                          v-if="foto"
                          :src="foto"
                          class="d-flex flex-row fill-height"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                              <span class="subtitle-1">no image</span>
                            </v-row>
                          </template>
                        </v-img>
                      </v-responsive>  
                    </v-card>
                    <!-- <v-layout justify-center>
                      <label class="subtitle-1" v-text="datakaryawan.NAMA"></label>
                    </v-layout> -->
                  </v-card>
                </v-layout>
              </div>

              <div v-show="viewvaksin1 == true">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Kode karyawan</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.KodeKaryawan"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Vaksin Ke</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Tanggal Vaksin</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Sertifikat</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">
                      <v-dialog
                        v-model="DialogLihatSertifikatVaksinModeMobile"
                        persistent
                        max-width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="text-capitalize"
                            color="red darken-4"
                            dark
                            depressed
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left>mdi-eye</v-icon>
                            Lihat
                          </v-btn>
                        </template>
                        <v-card outlined class="pa-1">
                          <v-card
                            outlined
                            color="grey lighten-2"
                            class="fill-height"
                          >
                            <v-responsive :aspect-ratio="4/3">
                              <v-img
                                v-if="foto"
                                :src="foto"
                                class="d-flex flex-row fill-height"
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    x-small
                                    @click="DialogLihatSertifikatVaksinModeMobile = false"
                                  >
                                    <v-icon>mdi-close-thick</v-icon>
                                  </v-btn>
                                </v-card-actions>

                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-responsive>  
                          </v-card>
                        </v-card>
                      </v-dialog>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Keterangan</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">-</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog Lihat Foto Karyawan -->
    <v-dialog
      v-model="DialogLihatFoto"
      max-width="300"
    > 
      <v-card outlined class="pa-1">
        <v-card
          outlined
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="fill-height"
        >
          <v-responsive :aspect-ratio="3/4">
            <v-img
              v-if="foto"
              :src="foto"
              class="d-flex flex-row fill-height"
            >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  x-small
                  @click="DialogLihatFoto = false"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-card-actions>

              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                  <span class="subtitle-1">no image</span>
                </v-row>
              </template>
            </v-img>
          </v-responsive>  
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Liat Kartu BPJS TK -->
    <v-dialog
      v-model="DialogLihatKartuBPJSTK"
      width="500"
    >
      <v-card tile outlined class="pa-1">
        <v-card
          tile
          outlined
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="fill-height"
        >
          <v-responsive :aspect-ratio="4/2.4">
            <v-img
              :aspect-ratio="800/500"
              class="d-flex flex-row fill-height"
              v-if="datakaryawan.PhotoBPJSTK"
              :src="server+datakaryawan.PhotoBPJSTK"
            >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  x-small
                  @click="DialogLihatKartuBPJSTK = false"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-card-actions>
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon large v-show="datakaryawan.BPJSTK == null" :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                  <span class="subtitle-1">no image</span>
                </v-row>
              </template>
            </v-img>
          </v-responsive>  
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Liat Kartu BPJS KES -->
    <v-dialog
      v-model="DialogLihatKartuBPJSKES"
      max-width="500"
    >
      <v-card tile outlined class="pa-1">
        <v-card
          tile
          outlined
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="fill-height"
        >
          <v-responsive :aspect-ratio="4/2.4">
            <v-img
              :aspect-ratio="800/500"
              class="d-flex flex-row fill-height"
              v-if="datakaryawan.PhotoBPJSKes"
              :src="server+datakaryawan.PhotoBPJSKes"
            >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  x-small
                  @click="DialogLihatKartuBPJSKES = false"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-card-actions>
              
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon large v-show="datakaryawan.PhotoBPJSKes == null" :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                  <span class="subtitle-1">no image</span>
                </v-row>
              </template>
            </v-img>
          </v-responsive>  
        </v-card>
      </v-card> 
    </v-dialog>

    <!-- Dialog Liat Kartu KTP -->
    <v-dialog
      v-model="DialogLihatKTP"
      max-width="500"
    >
      <v-card tile outlined class="pa-1">
        <v-card
          tile
          outlined
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="fill-height"
        >
          <v-responsive :aspect-ratio="4/2.4">
            <v-img
              :aspect-ratio="800/500"
              class="d-flex flex-row fill-height"
              v-if="datakaryawan.PhotoKTP"
              :src="server+datakaryawan.PhotoKTP"
            >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  x-small
                  @click="DialogLihatKTP = false"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-card-actions>
              
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon large v-show="datakaryawan.PhotoKTP == null" :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                  <span class="subtitle-1">no image</span>
                </v-row>
              </template>
            </v-img>
          </v-responsive>  
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Liat Kartu NPWP -->
    <v-dialog
      v-model="DialogLihatKartuNPWP"
      max-width="500"
    >
      <v-card tile outlined class="pa-1">
        <v-card
          tile
          outlined
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="fill-height"
        >
          <v-responsive :aspect-ratio="4/2.4">
            <v-img
              :aspect-ratio="800/500"
              class="d-flex flex-row fill-height"
              v-if="datakaryawan.PhotoNPWP"
              :src="server+datakaryawan.PhotoNPWP"
            >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  x-small
                  @click="DialogLihatKartuNPWP = false"
                >
                  <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-card-actions>
              
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-icon large :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'">mdi-image</v-icon>
                  <span class="subtitle-1">no image</span>
                </v-row>
              </template>
            </v-img>
          </v-responsive>  
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Lihat Anak -->
    <v-dialog
      v-model="DialogLihatDetailAnak"
      max-width="500px"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
    >
      <v-card
        outlined
        :color="$vuetify.theme.dark ? '' : 'grey lighten-3'"
        class="rounded-lg"
      >
        <v-toolbar flat>
          <v-list-item two-line>
            <v-list-item-action class="ml-n7" v-show="mobile == true">
              <v-btn class="mr-n3" icon @click="DialogLihatDetailAnak = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content class="mx-n4">
              <v-list-item-title class="title">Detail Anak</v-list-item-title>
              <v-list-item-subtitle class="caption" v-text="'Jumlah Anak : '+jmlanak"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="mr-n7" v-show="mobile == false">
              <v-btn icon @click="DialogLihatDetailAnak = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text :class="$vuetify.theme.dark ? '' : 'grey lighten-3'">
          <div class="mr-n3 ml-n3 mb-n2 mt-3"> 
            <v-layout justify-center v-show="jmlanak.length == 0 == true">
              <span>Tidak Ada Data</span>
            </v-layout>

            <v-sheet
              outlined
              class="rounded-lg"
              v-show="jmlanak.length == 0 == false"
            >
              <template v-for="(Anak, index) in anak">
                <v-list-item two-line :key="index">
                  <v-list-item-content>
                    <v-list-item-title><strong v-text="Anak.NamaAnak"></strong></v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1" v-text="'Anak Ke '+Anak.AnakKe"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider
                  v-if="(Anak, index) < anak.length - 1"
                  :key="index+'a'"
                ></v-divider>
              </template>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog Lihat Detail Vaksin -->
    <v-dialog
      scrollable
      v-model="DialogLihatDetailVaksin"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
      max-width="400px"
    >
      <v-card
        outlined
        :color="$vuetify.theme.dark ? '' : 'grey lighten-3'"
        :class="mobile ? 'tile' : 'rounded-lg'"
      >
        <!-- <v-toolbar flat>
          <v-list-item two-line>
            <v-list-item-action class="ml-n7" v-show="mobile == true">
              <v-btn class="mr-n3" icon @click="DialogLihatDetailVaksin = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content class="mx-n4">
              <v-list-item-title class="title">Detail Vaksin</v-list-item-title>
              <v-list-item-subtitle>
                <span class="caption" v-text="datakaryawan.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'"></span>
                <v-icon class="ml-1" color="success" x-small v-text="datakaryawan.vaksin.length == 0 ? '' : 'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-show="mobile == false">
              <v-btn icon @click="DialogLihatDetailVaksin = false">
                <v-icon >mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-toolbar> -->

        <v-card-actions style="height: 58px;" :class="$vuetify.theme.dark ? 'grey darken-4' : 'white'">
          <v-list-item two-line>
            <v-list-item-action class="ml-n7" v-show="mobile == true">
              <v-btn class="mr-n3" icon text height="47px" width="47px" @click="DialogLihatDetailVaksin = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content class="mx-n4">
              <v-list-item-title class="title">Detail Vaksin</v-list-item-title>
              <v-list-item-subtitle>
                <span class="caption" v-text="datakaryawan.vaksin.length == 0 ? 'Belum ada Vaksin' : 'Sudah di Vaksin'"></span>
                <v-icon class="ml-1" color="success" x-small v-text="datakaryawan.vaksin.length == 0 ? '' : 'mdi-checkbox-marked-circle'"></v-icon>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-show="mobile == false" class="mr-n7">
              <v-btn icon height="47px" width="47px" @click="DialogLihatDetailVaksin = false">
                <v-icon >mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-layout justify-center v-show="datakaryawan.vaksin.length == 0 == true">
              <span>Tidak Ada Data</span>
            </v-layout>
            <v-sheet
              outlined
              class="rounded-lg"
              v-show="datakaryawan.vaksin.length == 0 == false"
            >   
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Vaksin Ke</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.vaksin.length > 0 ? datakaryawan.vaksin[pagevaksin].VaksinKe : ''"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">
                    <span class="text-h5" v-text="datakaryawan.vaksin.length > 0 ? datakaryawan.vaksin[pagevaksin].VaksinKe : ''"></span> 
                  </v-avatar>
                </v-list-item-action>
              </v-list-item> 

              <v-divider></v-divider> 

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal Vaksin</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.vaksin.length > 0 ? datakaryawan.vaksin[pagevaksin].TanggalVaksin : ''"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> 

              <v-divider></v-divider> 

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Keterangan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.vaksin.length > 0 ? datakaryawan.vaksin[pagevaksin].Keterangan : ''"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider> 

              <v-list-item two-line>
                <v-list-item-content @click="LihatSertifikat = !LihatSertifikat">
                  <v-list-item-title><strong>Sertifikat</strong></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small @click="LihatSertifikat = !LihatSertifikat">
                    <v-icon>{{ LihatSertifikat ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item> 

              <v-expand-transition>
                <v-card tile outlined class="pa-1 ma-3 mt-n3" v-show="LihatSertifikat">
                  <v-sheet
                    outlined
                    :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                    class="fill-height"
                  >
                    <v-responsive :aspect-ratio="4/2.7">
                      <v-img
                        v-if="datakaryawan.vaksin.length > 0 ? server+datakaryawan.vaksin[pagevaksin].Sertifikat : null"
                        :src="datakaryawan.vaksin.length > 0 ? server+datakaryawan.vaksin[pagevaksin].Sertifikat : null"
                        class="d-flex flex-row fill-height"
                      >
                        <v-row
                          v-show="datakaryawan.vaksin.length < 0 == true"
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <span>No Image</span>
                        </v-row>
                      </v-img>
                    </v-responsive>  
                  </v-sheet>
                </v-card> 
              </v-expand-transition>
            </v-sheet>

            <div class="text-center pa-2" v-show="datakaryawan.vaksin.length == 0 == false">
              <v-btn icon text class="ma-2" @click="kembali">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>  
              <v-btn-toggle color="white" active-class="error" borderless mandatory v-model="pagevaksin">
                <v-btn class="subtitle-1" v-for="(btn,index) in datakaryawan.vaksin.length" :key="btn" @click="pagevaksin = index" v-text="btn"></v-btn>
              </v-btn-toggle>
              <v-btn icon text class="ma-2" @click="lanjut">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ketika akun belum terkait dengan data karyawan -->
    <v-card
      :color="$vuetify.theme.dark ? 'dark' : 'dark'"
      class="rounded-lg mx-auto elevation-3"
      outlined
      v-show="this.datakaryawan.visible == true"
    >
      <v-sheet color="transparent" flat height="150">
        <div class="text-center mb-4 mt-6"><v-icon x-large color="error">mdi-alert</v-icon></div>
        <p class="display-1 text-center">Karyawan</p>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
// import api from "@/services/http";
export default {
  props: ['datakaryawan','jmlanak','kontrak','jabatan','foto','anak','vaksin','fotovaksin'],
  data () {
    return {
      LihatSertifikat: false,
      server:process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+":8000" : location.origin+'/tss/public',
      pagevaksin:0,
      viewdatakaryawan: true,
      viewfotokaryawan: '',
      viewvaksin1: '',
      DialogLihatKartuBPJSTK: false,
      DialogLihatKartuBPJSKES: false,
      DialogLihatKTP: false,
      DialogLihatKartuNPWP: false,
      DialogLihatSertifikatVaksin: false,
      DialogLihatSertifikatVaksinModeMobile: false,
      DialogLihatDetailVaksin: false,
      DialogLihatDetailAnak: false,
      DialogLihatFoto: false,
      DialogDetailKaryawan: false,
      MasaKerja:0,
      transparent: 'rgba(255, 255, 255, 0)',
      mobile:null,
      windowSize: {x: 0, y: 0},
      token:null,
    }
  },

  mounted(){
    // console.log(this.datakaryawan)
    this.token = localStorage.getItem('token')
    // this.getdata()
    this.CekMasaKerja()
  },

  computed: {
    
  },

  watch: {
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {
    lanjut () {
      this.pagevaksin = this.pagevaksin + 1 === this.datakaryawan.vaksin.length ? 0 : this.pagevaksin + 1
    },
    kembali () {
      this.pagevaksin = this.pagevaksin - 1 < 0 ? this.datakaryawan.vaksin.length - 1 : this.pagevaksin - 1
    },

    KeluarDialogKaryawan () {
      this.DialogDetailKaryawan = false
      this.$nextTick(() => {

      })
    },

    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },

    MasaKerjaDetail(tgl){
      var TanggalMulai = new Date(tgl);
      var diffDate = new Date(new Date() - TanggalMulai);
      return ((diffDate.toISOString().slice(0, 4) - 1970) + " Tahun " +
          diffDate.getMonth() + " Bulan " + (diffDate.getDate()-1) + " Hari");
    },

    CekMasaKerja(){
      var TanggalSekarang = new Date()
      var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
      var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
      var Tahun = TanggalSekarang.getFullYear();
      const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
      const TanggalAwal = new Date(this.datakaryawan.TglMasuk)
      const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
      const HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
      this.MasaKerja = this.MasaKerjaDetail(this.datakaryawan.TglMasuk) +' / '+ HitungHari
    },

    showdatakaryawan () {
      this.viewdatakaryawan = true
      this.viewfotokaryawan = false
      this.viewvaksin1 = false
    },

    showfotokaryawan() {
      this.viewdatakaryawan = false
      this.viewfotokaryawan = true
      this.viewvaksin1 = false
    },
    
    showvaksin1() {
      this.viewdatakaryawan = false
      this.viewfotokaryawan = false
      this.viewvaksin1 = true
    },
  },
}
</script>

<style scoped>
  .ScrollCSSMobile::-webkit-scrollbar {
    display: none;
    width: 4px;
  }
  .ScrollCSSMobile::-webkit-scrollbar-thumb  {
    border: none;
    border-radius: 0px;
  }
  .ScrollCSSMobile::-webkit-scrollbar-track  {
    display: none;
  }

  .v-card {
    transition: opacity .4s ease-in-out;
  }

  .show-btns {
    color: rgba(255, 255, 255, 1) !important;
  }

</style>