<template>
  <div v-resize="onResize">
    <!-- Card Mode Desktop Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == false"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 110px;">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Cuti Saya</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Sisa Cuti -- '+datakaryawan.Cuti"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" >
              <span>Status -- </span>
              <strong class="success--text" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'">Disetujui</strong>
              <strong class="warning--text" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'">Diajukan</strong>
              <strong class="error--text text-truncate" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'">Tidak Disetujui</strong>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar
            size="45px"
            class="rounded-lg mt-4 ml-4"
            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          >
            <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">mdi-file-document-edit</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </div>

      <v-card-actions> 
        <v-btn
          depressed
          @click="DialogDetailCuti = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>Detail</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
          <v-badge dot color="success" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'"></v-badge>
          <v-badge dot color="warning" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'"></v-badge>
          <v-badge dot color="error" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'"></v-badge>
        </v-btn> 
        <v-btn
          depressed
          @click="DialogHistoryCuti = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>History</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
        </v-btn> 
      </v-card-actions>
    </v-card>
    <!-- Card Mode Desktop End -->

    <!-- Card Mode Mobile Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == true"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <div style="height: 185px;">  
        <v-card-actions>    
          <v-avatar
            size="55px"
            class="rounded-lg ma-2 mb-n2"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-2'"
          >
            <v-icon large :color="$vuetify.theme.dark ? 'error' : 'grey darken-4'">mdi-file-document-edit</v-icon>
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="DialogDetailCuti = true"
            class="text-capitalize rounded-lg mr-2">
            <span>Detail</span>
            <v-badge dot color="success" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'"></v-badge>
            <v-badge dot color="warning" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'"></v-badge>
            <v-badge dot color="error" offset-x="-2" offset-y="-10" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'"></v-badge>
          </v-btn> 
          <v-btn
            depressed
            @click="DialogHistoryCuti = true"
            class="text-capitalize rounded-lg mr-2">
            <span>History</span>
          </v-btn> 
        </v-card-actions>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="title">Cuti Saya</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Sisa Cuti -- '+datakaryawan.Cuti"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" >
              <span>Status -- </span>
              <strong class="success--text" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'">Disetujui</strong>
              <strong class="warning--text" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'">Diajukan</strong>
              <strong class="error--text text-truncate" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'">Tidak Disetujui</strong>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <!-- Card Mode Mobile end -->

    <!-- Dialog History Cuti -->
    <v-dialog
      scrollable
      max-width="450"
      v-model="DialogHistoryCuti"
    >
      <v-card
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '' : ''"
        :outlined="$vuetify.theme.dark ? true : false"
      >
        <v-toolbar flat>
          <v-toolbar-title :class="mobile ? 'title' : 'text-h5'">History Cuti</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="DialogHistoryCuti = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-simple-table
          fixed-header
          height="300px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Tanggal
                </th>
                <th class="text-left">
                  Alasan
                </th>
                <th class="text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in historycuti"
                :key="item.id"
              >
                <td>{{ item.TanggalMulai }}</td>
                <td>{{ item.AlasanCuti }}</td>
                <td>{{ item.StatusCuti }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>

    <!-- Dialog Detail Cuti   -->
    <v-dialog
      scrollable
      max-width="450"
      v-model="DialogDetailCuti"
    >
      <v-card
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '' : ''"
        :outlined="$vuetify.theme.dark ? true : false"
      >
        <v-toolbar flat>
          <v-toolbar-title :class="mobile ? 'title' : 'text-h5'">Detail Cuti</v-toolbar-title>
          <!-- <v-list-item-content>
            <v-list-item-title :class="mobile ? 'title' : 'title'">Detail Cuti</v-list-item-title>
            <v-list-item-subtitle v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'" class="success--text mb-1 mt-n1"><span>Cuti Disetujui</span></v-list-item-subtitle>
            <v-list-item-subtitle v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'" class="warning--text mb-1 mt-n1"><span>Cuti Diajukan</span></v-list-item-subtitle>
            <v-list-item-subtitle v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'" class="error--text mb-1 mt-n1"><span>Cuti Tidak Disetujui</span></v-list-item-subtitle>
          </v-list-item-content> -->

          <v-spacer></v-spacer>

          <v-btn icon @click="DialogDetailCuti = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text :class="$vuetify.theme.dark ? '' : 'grey lighten-4'">
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <div class="mb-n1">
              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length == 0"
                type="warning"
              >
                Cuti Belum Ada
              </v-alert>

              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Diajukan'"
                type="warning"
              >
                Cuti Di Ajukan
              </v-alert>

              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'"
                type="success"
              >
                Cuti Di Setujui
              </v-alert>
              
              <v-alert
                text
                dense
                class="rounded-lg"
                v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Tidak Disetujui'"
                type="error"
              >
                Cuti Tidak Disetujui
              </v-alert>
            </div> 
            <!-- v-text="'Cuti Tidak Disetujui Oleh '+ datacuti.length > 0 ? datacuti.approvalcuti[datacuti.approvalcuti.length-1].ApproveBy : ''" -->
            <!-- <v-alert text dense class="rounded-lg" type="success" v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'">Cuti Di Setujui</v-alert> -->

            <v-list outlined class="rounded-lg">
              <template v-for="(data,index) in datacuti.approvalcuti">
                <v-list-item
                  three-line
                  :key="index"
                  :color="data.StatusApproval == 'Approved' ? 'success' : 'error'"
                >
                  <v-list-item-avatar size="30px" :color="data.StatusApproval == 'Approved' ? 'success' : data.StatusApproval == 'Menunggu Approval' ? 'warning' : data.StatusApproval == 'Rejected' ? 'error' : ''">
                    <span class="subtitle-1 white--text" v-text="data.Level"></span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="data.StatusApproval == 'Approved' ? 'success--text' : data.StatusApproval == 'Menunggu Approval' ? 'warning--text' : data.StatusApproval == 'Rejected' ? 'error--text' : ''"
                    >
                      <strong>{{ data.StatusApproval }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{data.ApproveBy}}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{data.Keterangan}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-show="data.StatusApproval == 'Approved'" color="success">mdi-check-bold</v-icon>
                    <v-progress-circular v-show="data.StatusApproval == 'Menunggu Approval'" :size="25" color="warning" indeterminate></v-progress-circular>
                    <v-icon v-show="data.StatusApproval == 'Rejected'" color="error">mdi-close-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="index < datacuti.approvalcuti.length - 1"
                  :key="index+'a'"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn 
            v-show="datacuti.length != 0 && datacuti.StatusCuti == 'Disetujui'"
            block depressed
            class="mr-n2 ml-n2 text-capitalize"
            @click="BukaSurat"
            :color="$vuetify.theme.dark ? '' : 'success'"
          >
            Lihat Form
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Lihat SuratCuti -->
    <v-dialog
      v-model="DialogLihatSuratCuti"
      persistent
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card :class="$vuetify.theme.dark ? 'grey darken-4' : ''">
        <v-card-actions style="height: 64px;">
          <v-toolbar-title class="text-h5">
            Surat Permohonan Izin Cuti
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text outlined class="text-capitalize" depressed @click="CetakSurat">Cetak</v-btn>
          <v-btn text outlined class="text-capitalize" depressed @click="DialogLihatSuratCuti = false">Keluar</v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text :class="$vuetify.theme.dark ? 'dark' : 'grey lighten-3'">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-sheet outlined width="21cm" height="auto" class="ma-8 mx-auto elevation-15">
                <div id="cetakan" style="padding: 35px; width: 21cm; height: auto; margin: auto; font-family: 'Calibri', sans-serif; font-size: 14px;">
                  <!-- Kepala Surat Start -->
                  <table style="border: 1px solid; font-family: 'Calibri', sans-serif;">
                    <tr style="border: 1px solid;">
                      <td style="border: 1px solid; width: 30%; text-align: center;">
                        <img style="width: 188px; padding-top: 5px;" src="../assets/TA_Logo.png"/>
                      </td>
                      <td colspan="2" style="border: 1px solid; font-size: 15px; text-align: center;">
                        <div>Jl. Bung Tomo No. 49 RT. 11 Telp/Fax . 0541 260521, Samarinda Seberang,</div>
                        <div>Email : hrga@trinityauto.id</div>
                      </td>
                    </tr>
                    <tr>
                      <th style="border: 1px solid; font-size: 18px; width: 30%; padding: 10px;" rowspan="4">SURAT PERMOHONAN IZIN CUTI</th>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">No. Dokumen</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">FORMULIR HRD.12</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Tanggal Dibuat</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">01 Maret 2016</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Revisi</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">1</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">Halaman</td>
                      <td style="border: 1px solid; font-size: 14px; padding-left: 5px;">1-1</td>
                    </tr>
                  </table>
                  <!-- Kepala Surat End -->
                  
                  <!-- Isi Surat Strat -->
                  <div style="font-family: 'Calibri', sans-serif; font-size: 14px; margin-left: 10px; margin-right: 10px;">
                    <table style="margin-top: 10px;">
                      <tr>
                        <td style="text-align: left;" colspan="2" v-text="'No : '+datacuti.NoCuti"></td>
                        <td style="text-align: right;" colspan="2" v-text="'Samarinda, '+DibuatTgl"></td>
                      </tr>
                      <tr>
                        <td colspan="4">Sesuai kepentingannya, dengan ini diberikan izin cuti (<strong style="font-style: italic;">-TAHUNAN, -BERSALIN </strong>)* kepada karyawan/(ti) :</td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Nama</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="datacuti.length != 0 ? datacuti.karyawan.Nama : ''"></td>

                        <td rowspan="8" style="width: 20%; text-align: right;">
                          <div style="width: 120px; height: 120px; margin: auto;">
                            <figure class="qrcode" v-show="this.datacuti.approvalcuti.length">
                              <qrcode
                                style="width: 120px; height: 120px; margin-bottom: -6px;"
                                :value="'Tertanda '+datacuti"
                                tag="svg"
                                :options="{
                                  errorCorrectionLevel: 'Q',
                                  width: 120,
                                }"
                              ></qrcode>
                              <!-- <img
                                style="width: 50px; height: 50px;"
                                class="qrcode__image"
                                src="../assets/LogoTrinity.png"
                                alt="Barcode"
                              /> -->
                            </figure>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">NRK</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="datacuti.length != 0 ? datacuti.karyawan.NRK : ''"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Awal Kerja</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="datacuti.length != 0 ? datacuti.karyawan.TglMasuk : ''"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Jabatan</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="datacuti.length != 0 ? datacuti.karyawan.jabatan.NamaJabatan : ''"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%; vertical-align: top;">Keperluan Alasan</td>
                        <td style="width: 1%; vertical-align: top;">:</td>
                        <td style="width: 58%;" v-text="datacuti.length != 0 ? datacuti.AlasanCuti : ''"></td>
                      </tr>
                      <tr>
                        <td style="width: 23%;">Mulai Tanggal</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;">
                          <label v-text="datacuti.length != 0 ? datacuti.TanggalMulai : ''"></label>
                          <label style="margin-right: 10px; margin-left: 10px;">s/d</label>
                          <label v-text="datacuti.length != 0 ? datacuti.TanggalSelesai : ''"></label>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 22%;">Jumlah Cuti Diambil</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;">
                          <label v-text="datacuti.length != 0 ? datacuti.JumlahCuti : ''"></label>
                          <label style="margin-right: 10px; margin-left: 10px;">Sisa Hak Cuti</label>
                          <label v-text="datacuti.length != 0 ? datacuti.karyawan.Cuti : ''"></label>
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 22%;">Kembali Bekerja Tanggal</td>
                        <td style="width: 1%;">:</td>
                        <td style="width: 58%;" v-text="datacuti.TanggalKerjaKembali"></td>
                      </tr>
                      <tr>
                        <td colspan="4">Demikian surat permohonan izin cuti ini kami berikan agar dapat dipergunakan sebagai mana mestinya.</td>
                      </tr>
                    </table>

                    <table style="text-align: center; margin-top: 10px;">
                      <tr>
                        <td style="width: 25%;" colspan="1">Dibuat Oleh :</td>
                        <td colspan="2">Diperiksa Oleh :</td>
                        <td style="width: 25%;" colspan="1">Disetujui Oleh :</td>
                      </tr>
                      <tr>
                        <th style="width: 25%;" v-text="datacuti.length != 0 ? datacuti.karyawan.Nama : ''"></th>
                        <th style="width: 25%;">Rudy Tjan</th>
                        <th style="width: 25%;">Alfian Malik</th>
                        <th style="width: 25%;">Herdaru Purwianto</th>
                      </tr>
                      <tr>
                        <td style="width: 25%;">Pemohon</td>
                        <td style="width: 25%;">Head Dept</td>
                        <td style="width: 25%;">HR</td>
                        <td style="width: 25%;">Opr Div Head</td>
                      </tr>
                    </table> 
                  </div>
                </div>
              </v-sheet>
            </v-row>
          </v-container>
          <!-- <page size="A4" layout="landscape"></page> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/http";
export default {
  props: ['datakaryawan','jmlanak','kontrak','datacuti','foto','listcuti','historycuti'],
  data () {
    return {
      DialogDetailCuti: false,
      DialogHistoryCuti:false,
      DialogLihatSuratCuti:false,
      DibuatTgl:'',
      dialog_verifikasi_kontrak: false,
      mobile:null,
      detailcuti2:this.datacuti.approvalcuti[2],
      ShowValidasiKontrak:false,
      MasaKontrak:this.kontrak ? this.CekMasaKontrak() : 0,
      today:new Date().toJSON().slice(0,10).split('-').join('-'),
      windowSize: {x: 0, y: 0},
      token:null,
    }
  },

  mounted(){
    console.log(this.historycuti)
    this.token = localStorage.getItem('token')
    // this.getdata()
  },

  computed: {

  },

  watch: {
    // datacuti(){
    //   console.log(this.datacuti)
    // },
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {
    CetakSurat(){
      console.log('dor')
      console.log('ber',this.detailcuti)
    },
    BukaSurat(){
      console.log('data',this.datacuti)
      let DibuatTgl = this.datacuti.DibuatTgl
      this.DibuatTgl = this.FormatTanggal(DibuatTgl)
      this.DialogLihatSuratCuti = true
    },
    FormatTanggal(x){
      var todayTime = new Date(x);
      var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      var month = bulan[todayTime .getMonth()];
      var day = todayTime .getDate();
      var year = todayTime .getFullYear();
      return day + " " + month + " " + year;  
    },
    LanjutKontrak(){
      // alert('tes')
      this.dialog_verifikasi_kontrak = false
      api.post('/notifikasi?token='+this.token,{
            Kategori: "Info",
            Judul: "Perpanjang Kontrak",
            Konten: "Menyetujui Perpanjang Kontrak Kerja",
            Attachment:null,
            Dari: this.$user.KodeKaryawan,
            Tujuan: "Human Resources",
            },
            { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } }
            )
            .then((res)=>{
              if (res) {
                // this.Alert = true
                // console.log('ok lanjut')
              } else {
                //
              }
            })
    },
    CekMasaKontrak(){
      var TanggalSekarang = new Date()
      var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
      var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
      var Tahun = TanggalSekarang.getFullYear();
      const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
      const TanggalAwal = new Date(this.kontrak.TanggalAkhir)
      const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
      let HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
      // this.MasaKontrak = HitungHari
      HitungHari = TanggalAkhir > TanggalAwal ? 0 : HitungHari
      if (HitungHari < 8) {this.ShowValidasiKontrak = true}
      return HitungHari;
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>
<style>
  table{
    border-collapse:collapse;
    width: 100%;
  }
  .qrcode {
  display: inline-block;
  /* font-size: 0;
  margin-bottom: 0; */
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 25%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
  width: 20%;
}
</style>