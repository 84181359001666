<script>
import { Doughnut } from 'vue-chartjs'
export default {
  
  extends: Doughnut,
  props: ['chartdata', 'options','status'],

  data () {
    return {
      loaded: true,
    }
  },
  
  watch:{
    status(){
        if (this.status == true) {
            this.renderChart(this.chartdata, this.options)  
        }
        
    },
    
  },

  mounted(){
   this.renderChart(this.chartdata, this.options)
  },

  computed: {

  },

  
  methods: {
   render(){
      this.renderChart(this.chartdata, this.options)  
   }
  },
}
</script>