<template>
  <div v-resize="onResize">
    <v-card 
      rounded="lg"
      class="mx-auto"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 163px;">
        <div class="text-center caption pa-2 mb-4">Analisis Keterlambatan</div>
        <ChartBar :width="200" :height="103" :chartdata="chartdata" :options="options" :status="status"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import ChartBar from "@/components/ChartAnalisisKaryawan.vue"
// const ChartBar = () => import (/* webpackChunkName: "chart-bar"*/ '../components/ChartAnalisisKaryawan.vue')
// import api from "@/services/http";
// import { mdbBarChart, mdbContainer } from "mdbvue";
export default {
  props: ['chartdata', 'options','status'],
  components: {
    ChartBar,
  },

  data () {
    return {
      loaded: true,
      windowSize: {x: 0, y: 0},
      mobile:null,
    }
  },
  
  watch:{
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  mounted(){

  },

  computed: {

  },


  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>