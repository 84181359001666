<template>
  <div v-resize="onResize">
    <!-- Card Mode Desktop Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == false && !this.jabatan.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 110px;">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Jabatan</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="jabatan.NamaJabatan"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Departemen -- '+jabatan.Departemen"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Divisi -- '+jabatan.Divisi"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar
            size="45px"
            class="rounded-lg mt-4 ml-4"
            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          >
            <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">mdi-account-hard-hat</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </div>

      <v-card-actions> 
        <v-btn
          depressed
          @click="DialogDetailJabatan = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>Detail</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
        </v-btn> 
      </v-card-actions>
    </v-card>
    <!-- Card Mode Desktop End -->

    <!-- Card Mode Mobile Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == true && !this.jabatan.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <div style="height: 185px;">  
        <v-card-actions>    
          <v-avatar
            size="55px"
            class="rounded-lg ma-2 mb-n2"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-2'"
          >
            <v-icon large :color="$vuetify.theme.dark ? 'error' : 'grey darken-4'">mdi-account-hard-hat</v-icon>
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="DialogDetailJabatan = true"
            class="text-capitalize rounded-lg mr-2">
            <span>Detail</span>
          </v-btn> 
        </v-card-actions>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Jabatan</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="jabatan.NamaJabatan"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Departemen -- '+jabatan.Departemen"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Divisi -- '+jabatan.Divisi"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
    <!-- Card Mode Mobile end -->

    <!-- Dialog Detail Jabatan -->
    <v-dialog
      v-model="DialogDetailJabatan"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
      max-width="380"
    >
      <v-card
        outlined
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
      >
        <v-card-actions style="height: 58px;" :class="$vuetify.theme.dark ? 'grey darken-4' : 'white'">
          <v-btn icon class="ml-n3 mr-1" height="47px" width="47px" v-show="mobile == true" @click="DialogDetailJabatan = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title :class="mobile ? 'title' : 'text-h5'">Jabatan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="mr-n3" height="47px" width="47px" v-show="mobile == false" @click="DialogDetailJabatan = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">                  
            <v-sheet outlined class="rounded-lg">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Kode Jabatan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.KodeJabatan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Nama Jabatan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.NamaJabatan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Kode Perusahaan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.KodePerusahaan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Departemen</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.Departemen"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Divisi</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.Divisi"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Atasan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="jabatan.Atasan == null ? jabatan.Atasan : jabatan.Atasan != '-' ? jabatan.Atasan.NamaJabatan : jabatan.Atasan"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ketika akun belum terkait dengan data karyawan -->
    <v-card
      :color="$vuetify.theme.dark ? 'dark' : 'dark'"
      class="rounded-lg mx-auto elevation-3"
      outlined
      v-show="this.jabatan.visible == true"
    >
    <v-sheet color="transparent" flat height="150">
      <div class="text-center mb-4 mt-6"><v-icon x-large color="error">mdi-alert</v-icon></div>
            <p class="display-1 text-center">Jabatan</p>
    </v-sheet>
    </v-card>


  </div>
</template>

<script>
// import api from "@/services/http";
export default {
  props: ['datakaryawan','jmlanak','kontrak','jabatan','foto'],
  data () {
    return {
      mobile:null,
      windowSize: {x: 0, y: 0},
      DialogDetailJabatan: false,
      token:null,
    }
  },

  mounted(){
    this.token = localStorage.getItem('token')
    // this.getdata()
  },

  computed: {

  },

  watch: {
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {

    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>