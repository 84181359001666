<script>
import { Bar } from 'vue-chartjs'
export default {
  
  extends: Bar,
  props: ['chartdata', 'options','status'],

  data () {
    return {
      loaded: true,
    }
  },
  
  watch:{
    status(){
      // this.height = 180
        // this.width = 'auto'  
        this.renderChart(this.chartdata, this.options)  
    },
    
  },

  mounted(){
    
  },

  computed: {

  },

  
  methods: {
   
  },
}
</script>