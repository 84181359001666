<script>
import { Line } from 'vue-chartjs'
export default {
  
  extends: Line,
  props: ['chartdatabar', 'optionschartbar','StatusLineTerlambatHarian'],

  data () {
    return {
      loaded: true,
    }
  },
  
  watch:{
    StatusLineTerlambatHarian(){
        // console.log(this.status)
      // this.height = 180
        // this.width = 'auto'  
        if (this.StatusLineTerlambatHarian == true) {
            this.renderChart(this.chartdatabar, this.optionschartbar)  
        }
        
    },
    
  },

  mounted(){
   this.renderChart(this.chartdatabar, this.optionschartbar)
  },

  computed: {

  },

  
  methods: {
   render(){
      this.renderChart(this.chartdatabar, this.optionschartbar)  
   }
  },
}
</script>