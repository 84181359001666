<template>
  <v-dialog
    v-model="FormCutiKaryawanBeranda"
    persistent
    scrollable
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-card outlined class="rounded-lg">
      <v-toolbar flat height="64px">
        <!-- <v-toolbar-title class="text-h5 font-weight-medium">Buat Pengajuan Cuti</v-toolbar-title> -->
        <v-menu offset-y rounded="lg" v-model="MenuPengajuanCutiBeranda" v-show="MenuPengajuanCutiBeranda">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              height="48px"
              class="rounded-lg ml-n2"
              v-bind="attrs"
              v-on="on"
            >
              <span class="text-h5 font-weight-medium text-capitalize" v-show="Menu == 'PengajuanCuti'">Buat Pengajuan Cuti</span>
              <span class="text-h5 font-weight-medium text-capitalize" v-show="Menu == 'DataCuti'">Data Cuti</span>
              <span class="text-h5 font-weight-medium text-capitalize" v-show="Menu == 'DrafCuti'">Draf Cuti</span>
              <v-icon class="ml-1">{{ MenuPengajuanCutiBeranda ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </template>
          <v-list nav dense class="rounded-lg" width="278px">
            <v-list-item-group
              v-model="selectedItem"
              mandatory
            >
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click="MenuForm(item.to)"
                color="white"
                :active-class="$vuetify.theme.dark ? 'red darken-4' : 'red darken-4'"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2" v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        
        <v-spacer></v-spacer>

        <v-btn icon @click="Tutup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-sheet outlined class="rounded-lg mt-3 mb-n2 mr-n3 ml-n3">
          <PengajuanCuti v-show="Menu == 'PengajuanCuti'" :Menu="Menu" @ToastKirim="ToastKirim" @ToastSimpan="ToastSimpan"/>
          <DrafCuti v-show="Menu == 'DrafCuti'" :Menu="Menu"/>
          <DataCuti v-show="Menu == 'DataCuti'" :Menu="Menu"/>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import PengajuanCuti from '@/views/FormCuti/PengajuanCuti'
  import DrafCuti from '@/views/FormCuti/DrafCuti'
  import DataCuti from '@/views/FormCuti/DataCuti'
  export default {
    components: {
      PengajuanCuti, DrafCuti, DataCuti
    },
    props: ['FormCutiKaryawanBeranda'],
    data () {
      return {
        MenuPengajuanCutiBeranda: false,
        Menu:'PengajuanCuti',
        selectedItem: 0,
        items: [
          { text: 'Buat Pengajuan Cuti', icon: 'mdi-square-edit-outline', to: 'PengajuanCuti' },
          { text: 'Data Cuti', icon: 'mdi-file-document-edit-outline', to: 'DataCuti' },
          { text: 'Draf Cuti', icon: 'mdi-content-save-edit', to: 'DrafCuti' },
        ]
      }
    },

    watch:{

    },

    methods:{
      ToastKirim(){
        this.$swal.fire({
        toast: true,
        icon: 'success',
        title: 'Form Cuti Dikirim',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true})
        this.Menu = "DataCuti"
      },

      ToastSimpan(){
        this.$swal.fire({
        toast: true,
        icon: 'success',
        title: 'Form Cuti Disimpan di Draf',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true})
        this.Menu = "DrafCuti"
      },

      Tutup(){
        this.$emit('FormCutiKaryawanBeranda',false)
        this.Cuti = Object.assign({}, this.DefaultCuti)
        this.$parent.FormCutiKaryawanBeranda = false
      },

      MenuForm(data){
        this.Menu = data
      },

      SimpanCuti(){
        // alert('ok')
      },

      KirimCuti(){
        // alert('kirim')
      }
    
    },

    mounted() {

    },
  }
</script>