<template>
  <div v-resize="onResize">
    <!-- Card Mode Desktop Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == false && !this.kontrak.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
    >
      <div style="height: 110px;">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Kontrak</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Tersisa '+MasaKontrak+' Hari'"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="kontrak.TanggalMulai+' -- Mulai'"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="kontrak.TanggalAkhir+' -- Berakhir'"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar
            size="45px"
            class="rounded-lg mt-4 ml-4"
            :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          >
            <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">mdi-file-document-edit</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </div>

      <v-card-actions> 
        <v-btn
          depressed
          @click="DialogDetailKontrak = true"
          class="text-capitalize rounded-lg mr-2"
        >
          <span>Detail</span>
          <!-- <v-icon large>mdi-dots-horizontal</v-icon> -->
        </v-btn> 
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="25" class="mr-1 mb-n1" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)" v-bind="attrs" v-on="on" color="success">
              <span class="caption white--text" v-text="kontrak.NoKontrak"></span>
            </v-avatar>
          </template>
          <span>Kontak Ke {{ kontrak.NoKontrak }} Berjalan</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="25"  class="mr-1 mb-n1" v-show="new Date(kontrak.TanggalAkhir) < new Date(today)" v-bind="attrs" v-on="on" color="warning">
              <span class="caption white--text" v-text="kontrak.NoKontrak"></span>
            </v-avatar>
          </template>
          <span>Kontak Ke {{ kontrak.NoKontrak }} Telah Habis</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="25"  class="mr-1 mb-n1" v-show="kontrak.length == 0 == true" v-bind="attrs" v-on="on" color="blue darken-4">
              <!-- <span class="subtitle-1 white--text" v-text="kontrak.NoKontrak"></span> -->
              <v-icon color="white" small>mdi-information-variant</v-icon>
            </v-avatar>
          </template>
          <span>Kontak Belum Diisi</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <!-- Card Mode Desktop End -->

    <!-- Card Mode Mobile Start -->
    <v-card
      rounded="lg"
      class="mx-auto"
      v-show="mobile == true && !this.kontrak.visible"
      :elevation="mobile ? '10' : '5'"
      :outlined="($vuetify.theme.dark) ? true : false"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <div style="height: 185px;">  
        <v-card-actions>    
          <v-avatar
            size="55px"
            class="rounded-lg ma-2 mb-n2"
            :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-2'"
          >
            <v-icon large :color="$vuetify.theme.dark ? 'error' : 'grey darken-4'">mdi-file-document-edit</v-icon>
          </v-avatar>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="DialogDetailKontrak = true"
            class="text-capitalize rounded-lg mr-2">
            <span>Detail</span>
          </v-btn> 
        </v-card-actions>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="title">Kontrak</v-list-item-title>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="'Tersisa '+MasaKontrak+' Hari'"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="kontrak.TanggalMulai+' -- Mulai'"></v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle-1 d-inline-block text-truncate" v-text="kontrak.TanggalAkhir+' -- Berakhir'"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="35" class="mb-n1" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)" v-bind="attrs" v-on="on" color="success">
                  <span class="subtitle-1 white--text" v-text="kontrak.NoKontrak"></span>
                </v-avatar>
              </template>
              <span>Kontak Ke {{ kontrak.NoKontrak }} Berjalan</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="35" class="mb-n1" v-show="new Date(kontrak.TanggalAkhir) < new Date(today)" v-bind="attrs" v-on="on" color="warning">
                  <span class="subtitle-1 white--text" v-text="kontrak.NoKontrak"></span>
                </v-avatar>
              </template>
              <span>Kontak Ke {{ kontrak.NoKontrak }} Telah Habis</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="35" class="mb-n1" v-show="kontrak.length == 0 == true" v-bind="attrs" v-on="on" color="blue darken-4">
                  <!-- <span class="subtitle-1 white--text" v-text="kontrak.NoKontrak"></span> -->
                  <v-icon color="white">mdi-information-variant</v-icon>
                </v-avatar>
              </template>
              <span>Kontak Belum Diisi</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-card>
    <!-- Card Mode Mobile end -->

    <!-- Dialog Detail Kontrak Karyawan -->
    <v-dialog
      max-width="380"
      v-model="DialogDetailKontrak"
      :fullscreen="mobile ? true : false"
      :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
    >
      <v-card
        outlined
        class="rounded-lg"
        :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
      >
        <v-toolbar flat>
          <v-btn icon v-show="mobile == true" @click="DialogDetailKontrak = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title :class="mobile ? 'title mx-n4' : 'text-h5 mx-n4'">Kontrak</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon v-show="mobile == false" @click="DialogDetailKontrak = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        
        <v-divider></v-divider>
        <v-card-text :class="$vuetify.theme.dark ? '' : 'grey lighten-4'">
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <!-- Alert Perigatan Kontrak -->
            <div class="mb-3">
              <v-alert text dense type="info" class="rounded-lg" v-show="kontrak.length == 0 == true">
                Kontrak Belum Diisi
              </v-alert>

              <v-alert text dense type="success" class="rounded-lg" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)">
                Kontrak Berjalan
              </v-alert>

              <v-alert text dense type="warning" class="rounded-lg" v-show="new Date(kontrak.TanggalAkhir) < new Date(today)">
                Kontrak Telah Habis
              </v-alert>
            </div>

            <v-sheet
              outlined
              class="rounded-lg"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Karyawan</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="datakaryawan.Nama"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Masa Kontrak Tersisa</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="MasaKontrak+' Hari'"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Kontrak Ke</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="kontrak.NoKontrak"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar v-show="kontrak.length == 0 == false && new Date(kontrak.TanggalAkhir) > new Date(today)" :class="$vuetify.theme.dark ? 'success white--text' : 'success white--text'">
                  <span class="title" v-text="kontrak.NoKontrak"></span> 
                </v-list-item-avatar>

                <v-list-item-avatar v-show="kontrak.length == 0 == false && new Date(kontrak.TanggalAkhir) < new Date(today)" :class="$vuetify.theme.dark ? 'warning white--text' : 'warning white--text'">
                  <span class="title" v-text="kontrak.NoKontrak"></span> 
                </v-list-item-avatar>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal Mulai</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="kontrak.TanggalMulai"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title><strong>Tanggal Akhir</strong></v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" v-text="kontrak.TanggalAkhir"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-sheet> 

            <!-- <div class="mt-5" v-show="new Date(kontrak.TanggalAkhir) > new Date(today)">
              <div class="text-center">
                <v-avatar size="60" class="ma-3 mx-auto" :color="$vuetify.theme.dark ? 'green darken-3' : 'green lighten-4'">
                  <v-icon large :color="$vuetify.theme.dark ? 'grey darken-4' : 'success'">mdi-check-bold</v-icon>
                </v-avatar>
              </div>
              <div class="text-center">
                <strong class="subtitle-1">Kontrak Berjalan</strong>
              </div>
            </div>

            <div class="mt-5" v-show="new Date(kontrak.TanggalAkhir) < new Date(today)">
              <div class="text-center">
                <v-avatar size="60" class="ma-3 mx-auto" :color="$vuetify.theme.dark ? 'yellow darken-4' : 'yellow lighten-3'">
                  <v-icon large :color="$vuetify.theme.dark ? 'grey darken-4' : 'warning'">mdi-alert</v-icon>
                </v-avatar>
              </div>
              <div class="text-center">
                <strong class="subtitle-1">Kontrak Telah Habis</strong>
              </div>
            </div> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog Verifikasi Kontrak -->
    <v-dialog
      v-model="dialog_verifikasi_kontrak"
      persistent
      max-width="320"
    >
      <v-card class="rounded-lg">
        <v-toolbar flat>
          <v-toolbar-title class="title">
            <!-- <v-icon left color="yellow darken-3">mdi-alert</v-icon> -->
            Verifikasi Kontrak Kerja
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            text
            small
            class="mr-n2"
            @click="dialog_verifikasi_kontrak = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <p class="mt-4 text-center">
            Kontrak Akan Di Lanjutkan Setelah Anda Melakukan Verifikasi
          </p>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="LanjutKontrak"
          >
            <span class="title text-capitalize">Lanjut</span>
          </v-btn>
          <v-btn
            color="error"
            text
            @click="dialog_verifikasi_kontrak = false"
          >
            <span class="title text-capitalize">Tidak</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/http";
export default {
  props: ['datakaryawan','jmlanak','kontrak','jabatan','foto'],
  data () {
    return {
      dialog_verifikasi_kontrak: false,
      mobile:null,
      ShowValidasiKontrak:false,
      MasaKontrak:this.kontrak ? this.CekMasaKontrak() : 0,
      today:new Date().toJSON().slice(0,10).split('-').join('-'),
      windowSize: {x: 0, y: 0},
      DialogDetailKontrak: false,
      token:null,
    }
  },

  mounted(){
    // console.log(this.kontrak)
    this.token = localStorage.getItem('token')
    // this.getdata()
  },

  computed: {

  },

  watch: {
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {
    LanjutKontrak(){
      // alert('tes')
      this.dialog_verifikasi_kontrak = false
      api.post('/notifikasi?token='+this.token,{
            Kategori: "Info",
            Judul: "Perpanjang Kontrak",
            Konten: "Menyetujui Perpanjang Kontrak Kerja",
            Attachment:null,
            Dari: this.$user.KodeKaryawan,
            Tujuan: "Human Resources",
            },
            { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } }
            )
            .then((res)=>{
              if (res) {
                // this.Alert = true
                // console.log('ok lanjut')
              } else {
                //
              }
            })
    },
    CekMasaKontrak(){
      var TanggalSekarang = new Date()
      var Hari = String(TanggalSekarang.getDate()). padStart(2, '0')
      var Bulan = String(TanggalSekarang.getMonth() + 1).padStart(2, '0')
      var Tahun = TanggalSekarang.getFullYear();
      const SatuHari = 24 * 60 * 60 * 1000; // jam*menit*detik*milidetik
      const TanggalAwal = new Date(this.kontrak.TanggalAkhir)
      const TanggalAkhir = new Date(Tahun + '-' + Bulan + '-' + Hari)
      let HitungHari = Math.round(Math.abs((TanggalAwal - TanggalAkhir) / SatuHari))
      // this.MasaKontrak = HitungHari
      HitungHari = TanggalAkhir > TanggalAwal ? 0 : HitungHari
      if (HitungHari < 8) {this.ShowValidasiKontrak = true}
      return HitungHari;
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
</script>